import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "@velaro/velaro-component-lib";
import useApi from "@/hooks/useApi";
import React, { useState } from "react";
import Form, { defaultCannedResponseModel, CannedResponseModel } from "./Form";
import { set } from "lodash";
import LoadingSpinner from "@/components/LoadingSpinner";
import useSelectedTeam from "@/hooks/useSelectedTeam";
import { PrimaryBlueButton } from "@velaro/velaro-component-lib";

interface Props {
  cannedResponseId: number;
  onClose(): void;
  onCannedResponseUpdated(): void;
}

export default function EditCannedResponseModal(props: Props) {
  const api = useApi();
  const [sending, setSending] = useState(false);
  const [success, setSuccess] = useState<boolean | undefined>(undefined);
  const { teamId } = useSelectedTeam();
  const [cannedResponse, setCannedResponse] =
    React.useState<CannedResponseModel>(defaultCannedResponseModel);

  React.useEffect(() => {
    async function fetchCannedResponse() {
      const response = api.messaging.get(
        `CannedResponses/${props.cannedResponseId}`
      );
      setCannedResponse(await (await response).json());
    }

    fetchCannedResponse();
  }, [api.messaging, props.cannedResponseId]);

  async function handleSubmit() {
    setSending(true);
    const response = await api.messaging.put(
      `CannedResponses/${props.cannedResponseId}`,
      cannedResponse
    );

    setSuccess(response.ok);
    if (response.ok) {
      props.onCannedResponseUpdated();
    }
    setSending(false);
  }

  return (
    <Modal show={true}>
      <ModalHeader
        title="Edit Canned Response"
        onClose={() => props.onClose()}
      />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <ModalBody>
          <div className="p-4">
            <Form
              cannedResponse={cannedResponse}
              onChange={(updates) => {
                setCannedResponse((oldState) => ({ ...oldState, ...updates }));
              }}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="p-4 flex flex-col">
            {success === false && (
              <div className="flex flex-row mb-4">
                There was an error saving your changes. Please see Integration
                Log for more details.
              </div>
            )}
            {sending ? (
              <div className="ml-auto flex">
                Saving Changes...
                <LoadingSpinner />
              </div>
            ) : (
              <div className="ml-auto flex">
                <PrimaryBlueButton
                  onClick={() => handleSubmit()}
                  label="Save Changes"
                />
              </div>
            )}
          </div>
        </ModalFooter>
      </form>
    </Modal>
  );
}
