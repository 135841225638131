import NetSuiteLogo from "@velaro/velaro-component-lib/dist/images/NetSuite Logo.png";
import FormstackLogo from "@velaro/velaro-component-lib/dist/images/Formstack Logo.svg";
import { IconPlus } from "@velaro/velaro-component-lib/";

export const applications = [
  {
    id: "",
    name: "Request New App",
    category: "all",
    icon: "",
    subTitle:
      "Submit your application integration suggestion by completing our request form"
  },
  {
    id: "netsuite",
    name: "Netsuite CRM",
    category: "all",
    icon: NetSuiteLogo,
    subTitle:
      "Connect directly to manage and streamline your customer communications and data."
  },
  {
    id: "formstack",
    name: "Formstack",
    category: "all",
    icon: FormstackLogo,
    subTitle:
      "Collect, manage, and automate form submissions to enhance workflows and improve data accuracy."
  }
];
