import * as React from "react";
import { Topic } from "../types";
import { slugify } from "../helpers";
import useModelState from "../hooks/useModelState";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "@velaro/velaro-component-lib";
import { FormGroup } from "@velaro/velaro-component-lib";
import useApi from "@/hooks/useApi";

interface Props {
  onClose(): void;
  onSubmit(topic: Topic): void;
}

export default function TopicCreateModal(props: Props) {
  const [saving, setSaving] = React.useState(false);
  const [name, setName] = React.useState("");
  const [slug, setSlug] = React.useState("");
  const [modelState, setModelState] = useModelState();
  const api = useApi();

  async function handleSubmit() {
    setSaving(true);
    const response = await api.main.post("KBTopics", {
      name,
      slug
    });
    const json = await response.json();

    props.onSubmit(json);
    if (response.status === 400) {
      console.error(response);
    }

    setSaving(false);
  }

  return (
    <Modal show={true}>
      <ModalHeader title="Create Topic" onClose={() => handleSubmit()} />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <ModalBody>
          <FormGroup label="Name">
            {/* <label htmlFor="topic-name">{App.translate("app/kb/name")}</label> */}
            <input
              type="text"
              id="topic-name"
              className="form-control"
              autoFocus
              value={name}
              onChange={(e) => {
                setName(e.currentTarget.value);
                setSlug(slugify(e.currentTarget.value));
              }}
            />
          </FormGroup>
          <FormGroup label="Slug">
            {/* <label htmlFor="topic-slug">{App.translate("app/kb/slug")}</label> */}
            <input
              type="text"
              id="topic-slug"
              className="form-control"
              value={slug}
              onChange={(e) => setSlug(e.currentTarget.value)}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-default"
            disabled={saving}
            onClick={() => props.onClose()}
          >
            Cancel
          </button>
          <button type="submit" className="btn btn-success" disabled={saving}>
            Create Topic
            {/* {App.translate("app/kb/createtopic")} */}
          </button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
