import Input from "@/components/Input";
import Select from "@/components/Select";
import * as React from "react";
import { FormGroup } from "../Workflows/RightAside/RightAsideHelpers";
import { Invitation, invitationTypeDefs } from "./types";
import { FormValidator } from "@velaro/velaro-component-lib";

interface Props {
  onUpdate(invitation: Invitation): void;
  invitation: Invitation;
  errors: Record<string, string>;
}

export default function BaseInvitationEditor({
  invitation,
  errors,
  onUpdate
}: Props) {
  return (
    <>
      <FormGroup label="Type">
        <FormValidator error={errors["type"]}>
          <Select
            options={invitationTypeDefs}
            value={invitation.type}
            onChange={(type) => onUpdate({ ...invitation, type })}
          />
        </FormValidator>
      </FormGroup>
      <FormGroup label="Name">
        <FormValidator error={errors["name"]}>
          <Input
            value={invitation.name}
            onChange={(name) => onUpdate({ ...invitation, name })}
          />
        </FormValidator>
      </FormGroup>
      <FormGroup label="Description">
        <Input
          value={invitation.description}
          onChange={(description) => onUpdate({ ...invitation, description })}
        />
      </FormGroup>
    </>
  );
}
