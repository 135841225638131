import React, { useEffect, useState } from "react";
import AIChatbotSettingsRow from "./AIChatbotSettingsRow";
import AIChatbotSettingsTabs from "./AIChatbotSettingsTabs";
import useAIConfigs from "@/hooks/useAIConfigs";
import { LoadingSpinner } from "@velaro/velaro-component-lib";
import { useParams } from "react-router-dom";
import useApi from "@/hooks/useApi";
import useToastAlert from "@/hooks/useToastAlert";
import { Severity } from "@velaro/velaro-component-lib";
import { PrimaryBlueButton } from "@velaro/velaro-component-lib";

export default function AIChatbotSettingsPlayground() {
  const { id } = useParams();
  const { aiConfigurations, loading, update } = useAIConfigs();
  const [query, setQuery] = useState<string>("");
  const [results, setResults] = useState<string>("");
  const api = useApi();
  const toastAlert = useToastAlert();
  const indexName =
    aiConfigurations.find((config) => config.id === parseInt(id!))?.indexName ||
    "No Index Configured";

  if (loading) return <LoadingSpinner />;

  async function queryIndex() {
    if (query.length == 0) {
      toastAlert.displayToast(Severity.Error, "Query cannot be empty");
      return;
    }
    if (indexName === "No Index Configured") {
      toastAlert.displayToast(Severity.Error, "No Index has been selected");
      return;
    }
    console.log("Querying index");
    const result = await api.messaging.post(`AIConfiguration/QueryIndex`, {
      query: query
    });
    if (result.ok) {
      const json = await result.json();
      setResults(json);
    } else {
      toastAlert.displayToast(Severity.Error, "Failed to query index");
    }
  }

  return (
    <div className="flex flex-col">
      <AIChatbotSettingsTabs selectedTab="Playground" aiConfigId={id!} />
      <div className="bg-white p-6 border-[1px] rounded-2xl shadow-sm w-[750px] self-center">
        <div className="flex flex-col gap-2">
          <AIChatbotSettingsRow
            title="Search Index Name"
            subtitle=""
            input={<div className="text-slate-500">{indexName}</div>}
          />
          <div className="flex flex-row gap-2 align-middle">
            <AIChatbotSettingsRow
              title="Query Playground"
              subtitle="Enter a search term to test your index"
              input={
                <input
                  onChange={(e) => setQuery(e.target.value)}
                  type="text"
                  placeholder="Enter Test Query"
                  className="w-full p-2 border-[1px] rounded-md text-sm"
                />
              }
            />
          </div>
          <PrimaryBlueButton label="Query Index" onClick={queryIndex} />
          <div className="pt-4 flex flex-col">
            <div className="text-slate-500">Results:</div>
            <div className="text-black">{results}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
