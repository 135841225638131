import { Tooltip } from "@velaro/velaro-component-lib";
import IconClose from "@/icons/IconClose";
import IconHelpLight from "@/icons/IconHelpLight";
import { Toggle } from "@velaro/velaro-component-lib";
import * as React from "react";

export function ToggleGroup({
  toggled,
  label,
  changeToggle
}: {
  toggled: boolean;
  label: string;
  changeToggle: () => void;
}) {
  return (
    <div className="flex gap-2 my-3">
      <Toggle toggled={toggled} changeToggle={changeToggle} />
      <label>{label}</label>
    </div>
  );
}

export function Header({
  icon,
  type,
  message,
  onClose
}: {
  icon: React.ReactNode;
  type: string;
  message: string;
  onClose: () => void;
}) {
  return (
    <div className="py-2 border-b mb-6">
      <div className="flex justify-between">
        <div className="flex gap-2 mb-2">
          {icon}
          <div className="font-semibold ">{type}</div>
        </div>
        <IconClose className="cursor-pointer" onClick={onClose} />
      </div>
      <div className="w-80 text-slate-500 text-sm font-normal font-['Rubik'] leading-none">
        {message}
      </div>
    </div>
  );
}

export function FormGroup({
  label,
  tooltipText,
  children
}: {
  label?: string;
  tooltipText?: string;
  children: React.ReactNode;
}) {
  return (
    <div className="mb-4">
      {label && (
        <div className="flex items-center gap-1">
          <label className="font-medium">{label}</label>
          {tooltipText && (
            <Tooltip text={tooltipText} direction="right">
              <IconHelpLight />
            </Tooltip>
          )}
        </div>
      )}
      <div className="my-2">{children}</div>
    </div>
  );
}

export function Divider() {
  return <div className="w-full my-6 border-t border-slate-200"></div>;
}
