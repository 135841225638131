import * as React from "react";
import { useEffect, useState } from "react";
import { WorkflowNode, NodeId, getNodeIcon } from "../types";
import { EndNode, Connector, Node } from "./CanvasHelpers";
import { OptionNodes } from "./OptionNodes";
import { ConnectorData, DraggingData } from "../WorkflowsEditor";
import {
  getNodeOptions,
  getNodeColors,
  getNodeTitle,
  getNodeText
} from "../WorkflowNodeHelpers";
import useAIConfigs from "@/hooks/useAIConfigs";
import { LoadingSpinner } from "@velaro/velaro-component-lib";
import useTeams from "@/hooks/useTeams";
import useUsers from "@/hooks/useUsers";

interface Props {
  nodes: Record<NodeId, WorkflowNode>;
  scale: number;
  draggingData: DraggingData | null;
  selectedNodeId: NodeId | null;
  selectedConnector?: HTMLDivElement;
  onLoad: () => void;
  onSelectNode: (id: NodeId) => void;
  onSelectConnector: (data: ConnectorData) => void;
  onDeleteNode: (id: NodeId) => void;
  onCopyNode: (id: NodeId) => void;
  onDragStart: (id: NodeId, event: React.DragEvent<HTMLDivElement>) => void;
  onDragOverConnector: (data: ConnectorData) => void;
  onDragLeaveConnector: () => void;
  onDragEnd: (id: NodeId) => void;
}

export default function WorkflowsCanvas({
  nodes,
  scale,
  draggingData,
  selectedNodeId,
  selectedConnector,
  onLoad,
  onSelectNode,
  onSelectConnector,
  onDeleteNode,
  onCopyNode,
  onDragStart,
  onDragOverConnector,
  onDragLeaveConnector,
  onDragEnd
}: Props) {
  const [loaded, setLoaded] = useState(false);

  const { aiConfigurations, loading } = useAIConfigs();
  const { getDisplayNameForUser } = useUsers();
  const { getTeamNameById } = useTeams();

  useEffect(() => {
    if (loaded) {
      return;
    }
    //putting it in a setTimeout to make sure the onLoad is called after the component is rendered
    setTimeout(() => {
      onLoad();
    });
    setLoaded(true);
  }, [loaded, onLoad]);

  function renderChildOrEnd(child: NodeId | undefined) {
    child = child == undefined ? -1 : child;
    const node = nodes[child];
    if (!node) {
      return <EndNode />;
    }
    return renderNode(node);
  }

  function renderNode(node: WorkflowNode): React.ReactNode {
    const NodeIcon = getNodeIcon(node);

    const options = getNodeOptions(node);
    const hasOptions = !!options && options.length > 0;
    const nodeColors = getNodeColors(node);
    return (
      <>
        <Node
          selected={selectedNodeId === node.id}
          type={node.type}
          action={node.actionType}
          icon={<NodeIcon className="text-white" />}
          title={getNodeTitle(node)}
          text={getNodeText(
            node,
            nodes,
            aiConfigurations,
            getDisplayNameForUser,
            getTeamNameById
          )}
          name={node.name}
          hasOptions={hasOptions}
          nodeColors={nodeColors}
          onSelect={() => onSelectNode(node.id)}
          onCopy={() => onCopyNode(node.id)}
          onDelete={() => onDeleteNode(node.id)}
          onDragStart={(e) => onDragStart(node.id, e)}
          onDragEnd={() => onDragEnd(node.id)}
        />
        {hasOptions && (
          <OptionNodes
            scale={scale}
            nodeId={node.id}
            options={options}
            color={nodeColors.optionColor}
            selectedConnector={selectedConnector}
            draggingData={draggingData}
            onSelectNode={onSelectNode}
            renderChildOrEnd={renderChildOrEnd}
            onSelectConnector={onSelectConnector}
            onDragOverConnector={onDragOverConnector}
            onDragLeaveConnector={onDragLeaveConnector}
          />
        )}
        {!hasOptions &&
          node.actionType !== "jumpTo" &&
          node.actionType !== "condition" && (
            <>
              <Connector
                draggingData={draggingData}
                selectedConnector={selectedConnector}
                onClick={(el) =>
                  onSelectConnector({
                    el,
                    parentNodeId: node.id,
                    childNodeId: node.child
                  })
                }
                onDragEnter={(el) =>
                  onDragOverConnector({
                    el,
                    parentNodeId: node.id,
                    childNodeId: node.child
                  })
                }
                onDragLeave={onDragLeaveConnector}
              />
              {renderChildOrEnd(node.child)}
            </>
          )}
      </>
    );
  }

  return (
    <div
      id="canvas"
      className={`flex w-[5000px] h-[5000px] items-start justify-center`}
    >
      <div className="relative flex flex-col items-center justify-center">
        {loading && (
          <div className="h-full">
            <LoadingSpinner />
          </div>
        )}
        {!loading && renderNode(nodes[1])}
      </div>
    </div>
  );
}
