import LoadingSpinner from "@/components/LoadingSpinner";
import useApi from "@/hooks/useApi";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import SurveyEditor from "./SurveyEditor";
import { Survey } from "./types";
import useToastAlert from "@/hooks/useToastAlert";
import {
  IconPlus,
  PrimaryBlueButton,
  Severity
} from "@velaro/velaro-component-lib";
import Select from "@/components/Select";
import useSurveys from "@/hooks/useSurveys";

function defaultSurvey(): Survey {
  return {
    id: 0,
    name: "New Survey",
    questions: []
  };
}

export default function Surveys() {
  const api = useApi();
  const { surveys, setSurveys } = useSurveys();
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const survey = surveys[selectedIndex];
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = React.useState<Record<string, string>[]>([]);
  const toastAlert = useToastAlert();
  const [dirty, setDirty] = useState(false);

  const save = useCallback(async () => {
    if (!survey) {
      return;
    }
    let errors: Record<string, string>[] = [];
    setErrors(errors);

    survey.questions.forEach((q, i) => {
      if (!q.text) {
        const clone = [...errors];
        clone[i] = { text: "Question text is required" };
        setErrors(clone);
        errors = clone;
      }
    });
    if (errors.length > 0) {
      return;
    }

    setSaving(true);
    const resp = await api.messaging.post("Surveys", survey);
    if (resp.ok) {
      toastAlert.displayToast(Severity.Success, "Survey saved successfully");
      setDirty(false);
    } else {
      toastAlert.displayToast(Severity.Error, "Failed to save survey");
    }
    setSaving(false);
  }, [api.messaging, survey, toastAlert]);

  return (
    <>
      <div className="panel bg-white dark:bg-stone-300 rounded-md shadow-md mb-4 p-4 flex flex-col gap-4">
        {survey && (
          <Select
            options={surveys.map((s, i) => ({ label: s.name, value: i }))}
            value={selectedIndex}
            onChange={(index) => setSelectedIndex(Number(index))}
          />
        )}
        <div>
          <PrimaryBlueButton
            prefixIcon={<IconPlus />}
            label="New Survey"
            onClick={() => {
              setSurveys([...surveys, defaultSurvey()]);
              setSelectedIndex(surveys.length);
            }}
          />
        </div>
      </div>
      <div className="panel bg-white dark:bg-stone-300 rounded-md shadow-md mb-4 p-4">
        {!survey && (
          <div>You have no surveys. Start by creating a new one.</div>
        )}
        {survey && (
          <div className="overflow-auto">
            <SurveyEditor
              errors={errors}
              survey={survey!}
              update={(value) => {
                const clone = [...surveys];
                clone[selectedIndex] = value;
                setSurveys(clone);
                setDirty(true);
              }}
              remove={() => {
                const clone = [...surveys];
                clone.splice(selectedIndex, 1);
                setSurveys(clone);
                setSelectedIndex(0);
                setDirty(true);
              }}
            />
          </div>
        )}
      </div>
      {dirty && (
        <div>
          <PrimaryBlueButton
            label={saving ? "Saving..." : "Save"}
            onClick={save}
            disabled={saving}
          />
        </div>
      )}
    </>
  );
}
