import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "@velaro/velaro-component-lib";
import * as React from "react";
import { BubbleInvitation, Invitation, invitationTypeDefs } from "./types";
import {
  PrimaryBlueButton,
  SecondaryBlueButton
} from "@velaro/velaro-component-lib";
import { useState } from "react";
import BaseInvitationEditor from "./BaseInvitationEditor";
import useApi from "@/hooks/useApi";
import AuthService from "@/services/AuthService";
import { ExpressionEditor } from "@/components/Expressions/ExpressionEditor";
import { Expression } from "@/components/Expressions/types";
import useToastAlert from "@/hooks/useToastAlert";
import { Severity } from "@velaro/velaro-component-lib";

interface Props {
  onUpdate(invitation: Invitation): void;
  onClose(): void;
  invitation?: Invitation;
}

function getDefaultInvitationModel(): BubbleInvitation {
  return {
    siteId: AuthService.profile.siteId,
    type: "bubble",
    name: "New Invitation",
    description: "Engages visitors with a friendly message",
    triggerExpression: [[{ type: "timeOnPage", data: '{"seconds":10}' }]],
    messageDelaySeconds: 3,
    messages: ["Hello!"]
  };
}

export default function InvitationModal({
  invitation,
  onUpdate,
  onClose
}: Props) {
  const [workingModel, setWorkingModel] = useState<Invitation>(
    invitation || getDefaultInvitationModel()
  );

  const [errors, setErrors] = useState<Record<string, string>>({});
  const [saving, setSaving] = useState(false);
  const api = useApi();
  const { displayToast } = useToastAlert();
  const SubtypeEditor = invitationTypeDefs.find(
    (t) => t.value === workingModel.type
  )?.editor;

  const isNew = !workingModel.id;
  const buttonLabel = isNew
    ? saving
      ? "Creating"
      : "Create"
    : saving
    ? "Saving"
    : "Save";

  function validate() {
    const errors: Record<string, string> = {};
    if (!workingModel.type) {
      errors["type"] = "Type is required";
    }

    if (!workingModel.name) {
      errors["name"] = "A name is required";
    }

    if (workingModel.type === "bubble") {
      const bubble = workingModel as BubbleInvitation;
      if (bubble.messages.length === 0) {
        errors["messages"] = "At least one message is required";
      }

      if (bubble.messageDelaySeconds < 0) {
        errors["messageDelaySeconds"] =
          "Message delay must be a positive number";
      }
    }

    return errors;
  }

  const save = async () => {
    const errors = validate();
    setErrors(errors);
    const hasErrors = Object.keys(errors).length > 0;
    if (hasErrors) {
      return;
    }

    setSaving(true);
    const response = await api.messaging.post("Invitations", workingModel);

    if (response.ok) {
      displayToast(Severity.Success, "Invitation saved successfully.");
      onUpdate(await response.json());
    } else {
      const error = await response.json();
      displayToast(Severity.Error, JSON.stringify(error));
    }
    onClose();
    setSaving(false);
  };

  return (
    <Modal show={true} maxWidth="max-w-[1000px]">
      <ModalHeader
        title={isNew ? "Create Invitation" : "Edit Invitation"}
        onClose={onClose}
      />
      <ModalBody>
        <div className="flex gap-4">
          <div className="p-1 w-1/2 max-h-[700px] overflow-auto">
            <BaseInvitationEditor
              invitation={workingModel}
              errors={errors}
              onUpdate={setWorkingModel}
            />
            {SubtypeEditor && (
              <SubtypeEditor
                invitation={workingModel}
                errors={errors}
                onUpdate={setWorkingModel}
              />
            )}
          </div>
          <div className="border border-y border-slate-gray-50"></div>
          <div className="p-1 w-1/2 max-h-[700px] overflow-auto">
            <ExpressionEditor
              expressionType="send-invite"
              expression={workingModel.triggerExpression}
              onUpdate={(triggerExpression: Expression) => {
                setWorkingModel({
                  ...workingModel,
                  triggerExpression
                });
              }}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="flex gap-2 justify-end py-4">
          <SecondaryBlueButton label="Close" onClick={onClose} />
          <PrimaryBlueButton
            disabled={saving}
            label={buttonLabel}
            onClick={save}
          />
        </div>
      </ModalFooter>
    </Modal>
  );
}
