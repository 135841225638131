import * as React from "react";
import { FormGroup } from "../Workflows/RightAside/RightAsideHelpers";
import { BubbleInvitation } from "./types";
import Input from "@/components/Input";
import { IconClose } from "@velaro/velaro-component-lib";
import { TeriaryGrayButton } from "@velaro/velaro-component-lib";
import { IconPlus } from "@velaro/velaro-component-lib";
import { FormValidator } from "@velaro/velaro-component-lib";

interface Props {
  onUpdate(invitation: BubbleInvitation): void;
  invitation: BubbleInvitation;
  errors: Record<string, string>;
}

export default function BubbleInvitationEditor({
  invitation,
  errors,
  onUpdate
}: Props) {
  return (
    <>
      <FormGroup
        label="Message delay (seconds)"
        tooltipText="The number of seconds to wait between displaying each message"
      >
        <FormValidator error={errors["messageDelaySeconds"]}>
          <Input
            type="number"
            value={invitation.messageDelaySeconds}
            onChange={(value) =>
              onUpdate({
                ...invitation,
                messageDelaySeconds: parseInt(value) || 0
              })
            }
          />
        </FormValidator>
      </FormGroup>
      <FormGroup label="Messages">
        <FormValidator error={errors["messages"]}>
          <div className="flex flex-col gap-2">
            {invitation.messages.map((message, index) => (
              <div key={index}>
                <Input
                  value={message}
                  onChange={(value) => {
                    const newMessages = [...invitation.messages];
                    newMessages[index] = value;
                    onUpdate({ ...invitation, messages: newMessages });
                  }}
                  suffixIcon={
                    <div
                      className="cursor-pointer text-slate-500"
                      onClick={() => {
                        const newMessages = [...invitation.messages];
                        newMessages.splice(index, 1);
                        onUpdate({ ...invitation, messages: newMessages });
                      }}
                    >
                      <IconClose />
                    </div>
                  }
                />
              </div>
            ))}
            <TeriaryGrayButton
              label="Add Message"
              prefixIcon={<IconPlus />}
              onClick={() =>
                onUpdate({
                  ...invitation,
                  messages: [...invitation.messages, ""]
                })
              }
            />
          </div>
        </FormValidator>
      </FormGroup>
    </>
  );
}
