import * as React from "react";
import { useEffect, useState } from "react";
import NewFileEditor from "./NewFileEditor";
import FileDropHandler from "./FileDropHandler";
import { IconUploadCloud } from "@velaro/velaro-component-lib";

interface Props {
  onAddFiles(files: File[]): void;
}

export default function FileUpload(props: Props) {
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const [newFiles, setNewFiles] = useState<File[]>([]);

  useEffect(() => {
    props.onAddFiles(newFiles);
  }, [newFiles]);

  return (
    <>
      <input
        ref={fileInputRef}
        type="file"
        multiple
        style={{ display: "none" }}
        onChange={(e) => {
          const fileList = e.currentTarget.files;

          if (!fileList) {
            return;
          }

          const files: File[] = [];

          for (let i = 0; i < fileList.length; i++) {
            files.push(fileList[i]);
          }

          props.onAddFiles(files);
        }}
      />
      <div
        className="p-4 bg-slate-50  border-2 border-dashed border-slate-300 rounded-lg cursor-pointer relative"
        onClick={() => {
          fileInputRef.current?.click();
        }}
      >
        <div className="flex justify-center items-center p-2">
          <IconUploadCloud />
        </div>
        <div className="flex flex-col items-center justify-center ">
          <div className="flex flex-row text-slate-500 text-sm pb-2">
            Drop files here or Click to browse
          </div>
          <div className="flex flex-row text-slate-500 text-xs">
            Supported files: PDF, TXT, DOC, DOCX. Images won't be scraped.
          </div>
          <div className="flex flex-row text-slate-500 text-xs">
            You can upload a maximum of 5 files at once, with each file not
            exceeding 20 MB.
          </div>
        </div>
        <div>
          <FileDropHandler
            onDrop={(files) => {
              setNewFiles((s) => [...s, ...files]);
              props.onAddFiles(files);
            }}
          />
        </div>
      </div>
      <NewFileEditor files={newFiles} onUpdate={setNewFiles} />
    </>
  );
}
