import * as React from "react";
import { useRef, useState } from "react";
import { WorkflowListItem } from "./types";
import { toShortDate } from "@/helpers/dates";
import {
  ModalBody,
  ModalFooter,
  ModalHeader,
  Toggle,
  PrimaryBlueButton,
  SecondaryGrayButton,
  Modal
} from "@velaro/velaro-component-lib";
import { IconCopy } from "@velaro/velaro-component-lib";
import IconEditPencil2 from "@/icons/IconEditPencil2";
import { IconTrash } from "@velaro/velaro-component-lib";
import { useNavigate } from "react-router-dom";
import { MeatballMenuItem, MeatballMenu } from "@velaro/velaro-component-lib";

export function WorkflowItem({
  item,
  isLast,
  toggle,
  remove
}: {
  item: WorkflowListItem;
  isLast: boolean;
  toggle: () => void;
  remove: () => void;
}) {
  const [showConfirmRemoveModal, setShowConfirmRemoveModal] = useState(false);
  const dateCreated = new Date(item.dateCreated || "");
  const lastModified = new Date(item.lastModified || "");
  const dotsRef = useRef<SVGSVGElement>(null);
  const className = isLast ? "" : "border-b border-slate-200";
  const navigate = useNavigate();

  const menuItems: MeatballMenuItem[] = [
    {
      icon: IconEditPencil2,
      label: "Edit",
      onClick: () => navigate(`${item.id}`)
    },
    { icon: IconCopy, label: "Duplicate", onClick: () => {} },
    {
      icon: IconTrash,
      label: "Delete",
      onClick: () => {
        setShowConfirmRemoveModal(true);
      }
    }
  ];

  return (
    <tr className={`${className} h-12 hover:bg-slate-100`}>
      <td>
        <div className="flex items-center">
          <Checkbox />
        </div>
      </td>
      <td>{item.name}</td>
      <td>{item.createdBy}</td>
      <td>{toShortDate(lastModified)}</td>
      <td className="flex items-center h-12">
        <Toggle
          label={item.enabled ? "Published" : "Draft"}
          toggled={item.enabled}
          changeToggle={toggle}
        />
      </td>
      <td>
        <div className="relative flex justify-end">
          <MeatballMenu menuItems={menuItems} />
          <Modal show={showConfirmRemoveModal}>
            <ModalHeader
              title="Confirm Remove Workflow"
              onClose={() => setShowConfirmRemoveModal(false)}
            />
            <ModalBody>
              <div className="m-4">
                Are you sure you want to remove this workflow?
              </div>
            </ModalBody>
            <ModalFooter>
              <div className="mx-4 my-2 flex justify-between items-center">
                <SecondaryGrayButton
                  label={"Cancel"}
                  onClick={() => setShowConfirmRemoveModal(false)}
                />
                <PrimaryBlueButton
                  label={"Remove"}
                  onClick={() => {
                    setShowConfirmRemoveModal(false);
                    remove();
                  }}
                />
              </div>
            </ModalFooter>
          </Modal>
        </div>
      </td>
    </tr>
  );
}

export function Checkbox(props: React.HTMLProps<HTMLInputElement>) {
  return (
    <input
      className={
        "w-4 h-4 bg-white rounded-3xl border border-slate-300 justify-center items-center gap-2 inline-flex"
      }
      {...props}
      type="checkbox"
    />
  );
}
