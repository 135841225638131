import useTeams from "@/hooks/useTeams";
import React, { useMemo } from "react";
import { UserModel } from "../types";
import Select, { MultiValue } from "react-select";
import { NextButton } from "@/components/Buttons";
import { useNavigate } from "react-router-dom";
import IconArrowCircleLeft from "@/icons/IconArrowCircleLeft";
import CreateTeamModal from "../../Teams/CreateTeamModal";
import { FormValidator } from "@velaro/velaro-component-lib";
import {
  PrimaryBlueButton,
  SecondaryBlueButton
} from "@velaro/velaro-component-lib";
import IconPersonGroupAdd from "@/icons/IconPersonGroupAdd";
import { Team } from "../../Teams/types";

interface Option {
  value: number;
  label: string;
}

interface Props {
  user: UserModel;
  setUser(user: UserModel): void;
  setActiveStep(step: number): void;
}

export default function AssignTeamsWizardStep(props: Props) {
  const [errors, setErrors] = React.useState<Record<string, string>>({});
  const [showCreateTeamModal, setShowCreateTeamModal] = React.useState(false);
  const { teams, getTeamNameById, setTeams } = useTeams();

  const navigate = useNavigate();

  const teamOptions = useMemo(
    () => teams.map((x) => ({ value: x.id, label: x.name })),
    [teams]
  );

  function handleTeamChange(selectedOptions: MultiValue<Option>) {
    const selectedTeamIds = (selectedOptions as Option[]).map(
      (option) => option.value
    );
    props.setUser({ ...props.user, teamIds: selectedTeamIds });
  }

  const selectedTeams = useMemo(() => {
    return props.user.teamIds.map((x) => ({
      value: x,
      label: getTeamNameById(x)
    }));
  }, [getTeamNameById, props.user.teamIds]);

  function handleNext() {
    setErrors({});

    const newErrors: Record<string, string> = {};

    if (props.user.teamIds.length === 0) {
      newErrors["TeamIds"] = "Select at least one team";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length !== 0) {
      return;
    }

    props.setActiveStep(3);
  }

  function handleNewTeamCreated(team: Team) {
    setShowCreateTeamModal(false);
    const newTeamIds = [...props.user.teamIds, team.id];
    props.setUser({ ...props.user, teamIds: newTeamIds });
    setTeams([...teams, team]);
  }

  return (
    <>
      <div className="flex justify-center">
        <div className="panel bg-white dark:bg-stone-300 rounded-md shadow-md p-8 mt-6 mx-8 w-3/5">
          <div className="font-semibold text-lg mb-2">Assign Teams</div>
          <div className="mt-2">Choose an option</div>
          <div className="mt-2 flex justify-between">
            <FormValidator error={errors["TeamIds"]} className="w-2/3">
              <Select
                isMulti
                name="teams"
                options={teamOptions}
                value={selectedTeams}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(e) => handleTeamChange(e)}
              />
            </FormValidator>
            <div>
              <PrimaryBlueButton
                prefixIcon={<IconPersonGroupAdd />}
                label="Create New Team"
                onClick={() => setShowCreateTeamModal(true)}
              />
            </div>
          </div>
          {/* Coming soon: Skills */}
          {/* <div className="font-semibold text-lg mb-2 mt-16">Assign Skills</div>
        <div className="mt-2">
          Add or modify agent skills to tailor each agent's unique competencies
          and enhace customer engagement.
        </div> */}
          <div className="mt-4 flex w-full justify-between items-center">
            <div
              className="flex items-center text-slate-gray-400 cursor-pointer"
              onClick={() => props.setActiveStep(1)}
            >
              <IconArrowCircleLeft />
              <div className="ml-2">Previous Step</div>
            </div>
            <div className="flex">
              <div className="mr-2">
                <SecondaryBlueButton
                  label="Cancel"
                  onClick={() => navigate("/users")}
                />
              </div>
              <NextButton label="Next" onClick={() => handleNext()} />
            </div>
          </div>
        </div>
      </div>
      <CreateTeamModal
        show={showCreateTeamModal}
        onClose={() => setShowCreateTeamModal(false)}
        onTeamCreated={(team: Team) => handleNewTeamCreated(team)}
      />
    </>
  );
}
