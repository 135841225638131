import LoadingSpinner from "@/components/LoadingSpinner";
import TeamSelectDeprecated from "@/components/messaging/TeamSelect";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "@velaro/velaro-component-lib";
import Panel from "@/components/Panel";
import useApi from "@/hooks/useApi";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import useSelectedTeam from "@/hooks/useSelectedTeam";
import { PrimaryBlueButton } from "@velaro/velaro-component-lib";
import { set } from "lodash";

// Extend the Window interface to include fbAsyncInit
declare global {
  interface Window {
    fbAsyncInit: () => void;
    FB: any; // Add any other FB-related properties if needed
  }
}

interface FacebookLoginResponse {
  authResponse: {
    accessToken: string;
    expiresIn: number;
    signedRequest: string;
    userID: string;
    code: string;
  } | null;
  status: string;
}

interface WhatsAppConfig {
  id: number;
  tenantId: number;
  pageId: string;
  pageName: string;
  accessToken: string;
  phoneId: string;
}

interface WhatsAppAppSettings {
  whatsAppId: string;
  whatsAppConfigId: string;
}

export default function WhatsAppIntegration() {
  const [showSetupModal, setShowSetupModal] = useState(false);
  const api = useApi();
  const [pageName, setPageName] = useState("");
  const [pageId, setPageId] = useState("");
  const [token, setToken] = useState("");
  const [configs, setConfigs] = useState<WhatsAppConfig[]>([]);
  const [appSettings, setAppSettings] = useState<WhatsAppAppSettings>();
  const { teamId, updateTeamId } = useSelectedTeam();
  const [sending, setSending] = useState(false);
  const [success, setSuccess] = useState<boolean | undefined>(undefined);
  const [loadingAppSettings, setLoadingAppSettings] = useState(true);

  const fetchAppSettings = useCallback(async () => {
    setLoadingAppSettings(true);
    const response = await api.messaging.get("WhatsApp/Auth/GetAppSettings/");
    const settings = await response.json();
    setAppSettings(settings);
    setLoadingAppSettings(false);
  }, [api.messaging]);

  useEffect(() => {
    fetchAppSettings();
  }, [fetchAppSettings]);

  useEffect(() => {
    // Load the Facebook SDK script
    const fbScript = document.createElement("script");
    fbScript.src = "https://connect.facebook.net/en_US/sdk.js";
    fbScript.async = true;
    fbScript.defer = true;
    fbScript.crossOrigin = "anonymous";
    document.body.appendChild(fbScript);

    // Initialize the Facebook SDK
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: "559088305015876",
        autoLogAppEvents: true,
        xfbml: true,
        version: "v20.0"
      });
    };

    return () => {
      // Clean up the script when the component unmounts
      document.body.removeChild(fbScript);
    };
  }, []);

  // Function to launch the WhatsApp signup
  const launchWhatsAppSignup = () => {
    setSending(true);
    window.FB.login(
      function (response: FacebookLoginResponse) {
        if (response.authResponse) {
          getWhatsAppBusiness(response.authResponse.code);
          const accessToken = response.authResponse.accessToken;
          // Use this token to call the debug_token API and get the shared WABA's ID
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      },
      {
        config_id: "920701146565188",
        response_type: "code",
        override_default_response_type: true,
        extras: {
          setup: {
            // Prefilled data can go here
          }
        }
      }
    );
  };

  async function getWhatsAppBusiness(accessToken: string) {
    setSending(true);
    const response = await api.messaging.post("WhatsApp/Auth/GetBusinessId", {
      accessToken: accessToken,
      pageName: pageName,
      teamId: teamId
    });
    setSuccess(response.ok);
    if (response.ok) {
      setShowSetupModal(false);
      const fetchResponse = await api.messaging.get("WhatsApp/Integrations");
      setConfigs(await fetchResponse.json());
    }
    setSending(false);
  }

  async function handleSubmit() {
    setSending(true);
    const response = await api.messaging.post("WhatsApp/Auth/SaveCredentials", {
      pageId: pageId,
      pageName: pageName,
      accessToken: token,
      teamId: teamId
    });
    setSuccess(response.ok);
    if (response.ok) {
      setShowSetupModal(false);
      const fetchResponse = await api.messaging.get("WhatsApp/Integrations");
      setConfigs(await fetchResponse.json());
    }
    setSending(false);
  }

  useEffect(() => {
    async function fetchConfigs() {
      const response = await api.messaging.get("WhatsApp/Integrations");
      setConfigs(await response.json());
    }
    fetchConfigs();
  }, [api.messaging]);

  const deleteConfig = useCallback(
    async (id: number) => {
      if (confirm("Are you sure you want to delete this integration?")) {
        setConfigs((oldState) => {
          return oldState.filter((x) => x.id !== id);
        });
        await api.messaging.delete(`WhatsApp/Integrations/${id}`);
      }
    },
    [api.messaging]
  );

  if (loadingAppSettings) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Panel title="WhatsApp">
        <div className="p-4">
          <table className="p-4 w-full bg-slate-50">
            <thead>
              <tr>
                <th className="border p-2">Name</th>
                <th className="border p-2">WhatsApp Phone Id</th>
                <th className="border p-2"></th>
              </tr>
            </thead>
            <tbody>
              {configs.map((config) => (
                <tr key={config.id}>
                  <td className="border p-2">{config.pageName}</td>
                  <td className="border p-2">{config.phoneId}</td>
                  <td className="border p-2 text-center">
                    <button
                      className="hover:bg-crimson-crimson-red-300 font-semibold rounded-full py-2 px-4 text-crimson-crimson-red-600 fa fa-trash"
                      onClick={() => deleteConfig(config.id)}
                    ></button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button
            type="button"
            className="btn-green mt-4"
            onClick={() => {
              fetchAppSettings();
              setShowSetupModal(!showSetupModal);
            }}
          >
            Add WhatsApp Account
          </button>
        </div>
        {showSetupModal && (
          <Modal show={true}>
            <ModalHeader
              title="Add WhatsApp Account"
              onClose={() => setShowSetupModal(!showSetupModal)}
            />
            <form
              className="px-4"
              onSubmit={(e) => {
                e.preventDefault();
                //handleSubmit();
              }}
            >
              <ModalBody>
                <div className="flex mb-4">
                  <div className="pr-2">Name</div>
                  <input
                    type="text"
                    className="input w-full"
                    value={pageName}
                    onChange={(e) => setPageName(e.currentTarget.value)}
                  />
                </div>
                {/* <div className="flex mb-4">
                  <div className="w-1/3 font-semibold">Page Id</div>
                  <input
                    type="text"
                    className="input w-2/3"
                    value={pageId}
                    onChange={(e) => setPageId(e.currentTarget.value)}
                  />
                </div>
                <div className="flex mb-4">
                  <div className="w-1/3 font-semibold">Token</div>
                  <input
                    type="text"
                    className="input w-2/3"
                    value={token}
                    onChange={(e) => setToken(e.currentTarget.value)}
                  />
                </div> */}
                <div className="flex mb-4">
                  {/* <div className="w-1/3 font-semibold">Team</div> */}
                  <TeamSelectDeprecated
                    className="input w-full"
                    onChange={(e) =>
                      updateTeamId(Number(e.currentTarget.value))
                    }
                    value={Number(teamId)}
                  />
                </div>
              </ModalBody>
              <ModalFooter>
                <div className="p-4 flex flex-col">
                  <div className="mt-4 flex justify-center">
                    {sending || loadingAppSettings ? (
                      <div className="flex justify-center items-center">
                        Saving Changes...
                        <LoadingSpinner />
                      </div>
                    ) : (
                      <button
                        className="bg-blue-600 text-white font-bold py-2 px-6 rounded"
                        onClick={launchWhatsAppSignup}
                      >
                        Connect to WhatsApp
                      </button>
                    )}
                  </div>
                </div>
              </ModalFooter>
            </form>
          </Modal>
        )}
      </Panel>
    </>
  );
}
