import TeamSelectDeprecated from "@/components/messaging/TeamSelect";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "@velaro/velaro-component-lib";
import React, { useState } from "react";
import { SmsIntegrationModel } from "../types";
import LoadingSpinner from "@/components/LoadingSpinner";

interface Props {
  onClose(): void;
  release(): Promise<void>;
  update(integration: SmsIntegrationModel): void;
  integration: SmsIntegrationModel;
  error: string;
}

export default function SmsIntegrationEditModal(props: Props) {
  const [selectedTeam, setSelectedTeam] = useState(props.integration.teamId);
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const [success, setSuccess] = useState<boolean | undefined>(undefined);

  async function release() {
    setLoading(true);
    await props.release();
    setLoading(false);
  }

  return (
    <Modal show={true}>
      <ModalHeader title="Edit Phone Number" onClose={() => props.onClose()} />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          props.update({ ...props.integration, teamId: selectedTeam });
        }}
      >
        <ModalBody>
          <div className="p-4">
            <div className="flex mb-4">
              <div className="w-1/3 font-semibold">Team</div>
              <TeamSelectDeprecated
                id="ar-team"
                className="input w-2/3"
                onChange={(e) => setSelectedTeam(e.currentTarget.value)}
                value={selectedTeam}
              />
            </div>
            <div className="text-crimson-red-500 text-sm mt-2">
              {props.error}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="p-4 flex flex-col mt-4">
            {success === false && (
              <div className="flex flex-row mb-4">
                There was an error saving your changes. Please see Integration
                Log for more details.
              </div>
            )}
            {loading ? (
              <div className="ml-auto flex">
                Saving Changes...
                <LoadingSpinner />
              </div>
            ) : (
              <div className="ml-auto flex flex-row">
                <button
                  type="submit"
                  className="bg-cornflower-blue-500 hover:bg-cornflower-blue-700 text-white py-2 px-4 rounded-full text-sm font-medium"
                  disabled={loading}
                >
                  Save
                </button>
                <button
                  type="submit"
                  className="btn btn-red ml-2"
                  disabled={loading}
                  onClick={() => release()}
                >
                  Release Phone Number
                </button>
              </div>
            )}
          </div>
        </ModalFooter>
      </form>
    </Modal>
  );
}
