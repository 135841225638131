import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "@velaro/velaro-component-lib";
import React, { useState } from "react";
import { StyleConfigModel } from "../../types";
import useApi from "@/hooks/useApi";
import useStyleConfigs from "../hooks/useStyleConfigs";
import { set } from "lodash";
import useToastAlert from "@/hooks/useToastAlert";
import { Severity } from "@velaro/velaro-component-lib";
import { FormValidator } from "@velaro/velaro-component-lib";

interface Props {
  close(): void;
  setSelectedConfigId(id: number): void;
  setWorkingConfig(config: StyleConfigModel): void;
}

export default function AddStyleConfigModal(props: Props) {
  const [saving, setSaving] = useState(false);
  const [name, setName] = useState("");
  const [errors, setErrors] = React.useState<Record<string, string>>({});
  const api = useApi();
  const { styleConfigs, setStyleConfigs } = useStyleConfigs();
  const toastAlert = useToastAlert();

  async function save() {
    setSaving(true);
    const resp = await api.messaging.post("MessengerStyleConfig/Add", { name });
    if (resp.ok) {
      const config = await resp.json();
      setStyleConfigs([...styleConfigs, config]);
      props.setSelectedConfigId(config.id);
      props.setWorkingConfig(config);
      toastAlert.displayToast(
        Severity.Success,
        "Style Config added successfully"
      );
      props.close();
    } else if (resp.status === 400) {
      const errorResponse = await resp.json();
      if (errorResponse.errors == null) {
        toastAlert.displayToast(Severity.Error, errorResponse);
        setSaving(false);
        return;
      }
      setErrors(errorResponse.errors);
    } else {
      toastAlert.displayToast(
        Severity.Error,
        "Something went wrong. Please try again."
      );
    }
    setSaving(false);
  }

  return (
    <Modal maxWidth="max-w-2xl" show={true}>
      <>
        <ModalHeader title={"Add Style Config"} onClose={props.close} />
        <ModalBody>
          <div className="p-4">
            <label>Name</label>
            <FormValidator error={errors["Name"]} className="my-2">
              <input
                type="text"
                className="w-full input"
                onChange={(e) => setName(e.target.value)}
              />
            </FormValidator>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="flex p-4">
            <div className="ml-auto">
              <button
                disabled={saving}
                className="btn-green btn-xs float-right"
                onClick={() => {
                  save();
                }}
              >
                {saving ? "Saving..." : "Save"}
              </button>
            </div>
          </div>
        </ModalFooter>
      </>
    </Modal>
  );
}
