import useApi from "@/hooks/useApi";
import React, { useCallback, useEffect, useState } from "react";
import LoadingSpinner from "@/components/LoadingSpinner";
import { WorkflowListItem } from "@/pages/Messaging/Workflows/types";
import { WorkflowsContext } from "@/context/WorkflowsContext";

export function WorkflowsProvider(props: { children: React.ReactNode }) {
  const [workflows, setWorkflows] = useState<WorkflowListItem[]>([]);
  const [loading, setLoading] = useState(true);
  const api = useApi();

  const fetch = useCallback(async () => {
    const response = await api.messaging.get("Workflows/List");
    const data = await response.json();
    setWorkflows(data);
    setLoading(false);
    return data;
  }, [api.messaging]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <WorkflowsContext.Provider
      value={{
        workflows,
        setWorkflows
      }}
    >
      {props.children}
    </WorkflowsContext.Provider>
  );
}
