import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "@velaro/velaro-component-lib";
import useApi from "@/hooks/useApi";
import React from "react";
import TeamSelectDeprecated from "@/components/messaging/TeamSelect";

interface FacebookIntegration {
  id: number;
  pageName: string;
  teamId: number | undefined;
}

interface Props {
  integrationId: number;
  onClose(): void;
  onUpdate(): void;
}

const defaultFacebookIntegration: FacebookIntegration = {
  id: 0,
  pageName: "",
  teamId: undefined
};

export default function FacebookIntegrationEditModal(props: Props) {
  const api = useApi();

  const [integration, setIntegration] = React.useState<FacebookIntegration>(
    defaultFacebookIntegration
  );

  React.useEffect(() => {
    async function fetchIntegration() {
      const response = await api.messaging.get(
        `Facebook/Integrations/${props.integrationId}`
      );

      setIntegration(await response.json());
    }

    fetchIntegration();
  }, [api.messaging, props.integrationId]);

  function update(updates: Partial<FacebookIntegration>) {
    setIntegration((oldState) => ({
      ...oldState,
      ...updates
    }));
  }

  async function persist() {
    const response = await api.messaging.put(
      `Facebook/Integrations/${props.integrationId}`,
      integration
    );

    if (response.ok) {
      props.onUpdate();
    } else {
      alert("error");
    }
  }

  return (
    <Modal show={true}>
      <ModalHeader
        title={integration.pageName}
        onClose={() => props.onClose()}
      />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          persist();
        }}
      >
        <ModalBody>
          <div className="p-4">
            <div>
              <label htmlFor="team" className="block mb-1">
                Team
              </label>
              <TeamSelectDeprecated
                id="team"
                className="input w-full"
                value={integration.teamId}
                onChange={(e) =>
                  update({ teamId: parseInt(e.currentTarget.value) })
                }
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="flex p-4">
            <div className="ml-auto">
              <button type="submit" className="btn-green">
                Save
              </button>
            </div>
          </div>
        </ModalFooter>
      </form>
    </Modal>
  );
}
