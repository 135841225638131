import { Rule } from "@/pages/Messaging/Rules/types";
import React from "react";
export const RulesContext = React.createContext<{
  rules: Rule[];
  loading: boolean;
  saving: boolean;
  add(rule: Rule): void;
  update(rule: Rule): void;
  remove(rule: Rule): void;
}>({
  rules: [],
  loading: false,
  saving: false,
  add() {},
  update() {},
  remove() {}
});
