import useApi from "@/hooks/useApi";
import React, { useEffect, useState } from "react";
import { StyleConfigModel } from "../../types";
import StyleConfigsContext from "../context/StyleConfigContext";
import useToastAlert from "@/hooks/useToastAlert";
import { Severity } from "@velaro/velaro-component-lib";

export default function StyleConfigsProvider({
  children
}: {
  children: React.ReactNode;
}) {
  const api = useApi();
  const [styleConfigs, setStyleConfigs] = useState<StyleConfigModel[]>([]);
  const [loading, setLoading] = useState(true);
  const { displayToast } = useToastAlert();

  useEffect(() => {
    async function fetchAll() {
      setLoading(true);
      const resp = await api.messaging.get(`MessengerStyleConfig/GetAll`);
      if (resp.ok) {
        setStyleConfigs(await resp.json());
        setLoading(false);
      } else {
        displayToast(
          Severity.Error,
          "An error occurred while fetching the style configs"
        );
      }
    }

    fetchAll();
  }, [api.messaging, displayToast]);

  return (
    <StyleConfigsContext.Provider
      value={{
        styleConfigs,
        setStyleConfigs,
        loading
      }}
    >
      {children}
    </StyleConfigsContext.Provider>
  );
}
