import useApi from "@/hooks/useApi";
import React, { useCallback, useEffect, useState } from "react";
import { LanguageConfigModel } from "../../types";
import LanguageConfigsContext from "../context/LanguageConfigContext";
import useSelectedTeam from "@/hooks/useSelectedTeam";
import useToastAlert from "@/hooks/useToastAlert";
import { Severity } from "@velaro/velaro-component-lib";

// const languageList = [
//   { code: "en", label: "English" },
//   { code: "es", label: "Spanish" }
// ];

export default function LanguageConfigsProvider({
  children
}: {
  children: React.ReactNode;
}) {
  const [languageConfigs, setLanguageConfigs] = useState<LanguageConfigModel[]>(
    []
  );
  const [loading, setLoading] = useState(true);
  const api = useApi();
  const { displayToast } = useToastAlert();

  useEffect(() => {
    async function fetchAll() {
      setLoading(true);
      const resp = await api.messaging.get(`MessengerLanguageConfig/GetAll`);
      if (resp.ok) {
        setLanguageConfigs(await resp.json());
        setLoading(false);
      } else {
        displayToast(
          Severity.Error,
          "An error occurred while fetching the language configs"
        );
      }
    }
    fetchAll();
  }, [api.messaging, displayToast]);

  return (
    <LanguageConfigsContext.Provider
      value={{
        languageConfigs,
        setLanguageConfigs,
        loading
      }}
    >
      {children}
    </LanguageConfigsContext.Provider>
  );
}
