import * as React from "react";
import useStorageFiles, { FileListItem } from "@/kb/hooks/useStorageFiles";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "@velaro/velaro-component-lib";
import { LoadingSpinner } from "@velaro/velaro-component-lib";

interface Props {
  onClose(): void;
  onSelect(payload: { text: string; url: string }): void;
}

export default function StorageModal(props: Props) {
  const { files, fetchFiles, fetchingFiles, tags, tagAssociations } =
    useStorageFiles();

  const [selectedTagId, setSelectedTagId] = React.useState("");
  const [searchText, setSearchText] = React.useState("");

  /**
   * Reduces file list based on selected tags and associations
   */

  const applyTagFilter = React.useCallback(
    (files: FileListItem[]) => {
      if (selectedTagId === "") {
        return files;
      }

      const fileIdMap: { [fileId: string]: boolean } = {};

      tagAssociations
        .filter((x: any) => x.TagId === selectedTagId)
        .forEach((association: any) => {
          fileIdMap[association.FileId] = true;
        });

      return files.filter((x) => fileIdMap[x.Id] === true);
    },
    [selectedTagId, tagAssociations]
  );

  /**
   * Reduces file list based on search input
   */

  const applySearchFilter = React.useCallback(
    (files: FileListItem[]) => {
      if (!searchText) {
        return files;
      }

      return files.filter((x) =>
        x.Name.toLowerCase().includes(searchText.toLowerCase())
      );
    },
    [searchText]
  );

  const displayedFiles = React.useMemo(() => {
    let filtered = [...files];

    filtered = applyTagFilter(filtered);
    filtered = applySearchFilter(filtered);

    return filtered.sort((a, b) => {
      if (a.Name === b.Name) {
        return 0;
      }

      if (a.Name < b.Name) {
        return -1;
      }

      return 1;
    });
  }, [applySearchFilter, applyTagFilter, files]);

  return (
    <Modal show={true}>
      <ModalHeader title="Storage" onClose={() => props.onClose()} />
      <ModalBody>
        {fetchingFiles && <LoadingSpinner />}
        {!fetchingFiles && (
          <>
            <div className="form-group">
              <select
                className="form-control mar-right"
                value={selectedTagId}
                onChange={(e) => setSelectedTagId(e.currentTarget.value)}
                style={{ marginRight: 8 }}
              >
                <option value="">All Tags</option>
                {tags.map((tag: any) => (
                  <option key={tag.Id} value={tag.Id}>
                    {tag.Name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Search..."
                value={searchText}
                onChange={(e) => setSearchText(e.currentTarget.value)}
              />
            </div>
            <table className="table table-condensed table-hover">
              <tbody>
                {displayedFiles.map((file) => (
                  <tr
                    key={file.Id}
                    onClick={() =>
                      props.onSelect({ text: file.Name, url: file.Url })
                    }
                  >
                    <td>{file.Name}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-default" onClick={() => fetchFiles()}>
          <i className="fa fa-refresh" /> Refresh
        </button>
        <a className="btn btn-default" href="/admin/storage" target="_blank">
          <i className="fa fa-cloud" /> Manage Files
        </a>
      </ModalFooter>
    </Modal>
  );
}
