import * as React from "react";
import { IconPlus } from "@velaro/velaro-component-lib";
import { useNavigate } from "react-router-dom";
import { PrimaryBlueButton } from "@velaro/velaro-component-lib";
export default function NewWorkflowButton() {
  const navigate = useNavigate();
  return (
    <>
      <PrimaryBlueButton
        prefixIcon={<IconPlus />}
        onClick={() => navigate("create")}
        label="Create Workflow"
      />
    </>
  );
}
