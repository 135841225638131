import useAIConfigs from "@/hooks/useAIConfigs";
import useTeams from "@/hooks/useTeams";
import useRules from "@/hooks/useRules";
import { SecondaryBlueButton } from "@velaro/velaro-component-lib";
import React from "react";
import { RuleValue, triggerDefs, Rule } from "./types";
import { getExpressionLabel } from "@/components/Expressions/types";
import useWorkflows from "@/hooks/useWorkflows";
import useSurveys from "@/hooks/useSurveys";

interface Props {
  rule: Rule;
  editRule(): void;
}

export default function RuleRowItem({ rule, editRule }: Props) {
  const { remove } = useRules();
  const { aiConfigurations } = useAIConfigs();
  const { workflows } = useWorkflows();
  const { getTeamNameById } = useTeams();
  const { surveys } = useSurveys();
  //this gives us a name instead of the id
  function getRuleValueLabel(key: string, value: RuleValue) {
    const result = value[key as keyof typeof rule.value] as string;
    if (!result) {
      return "<No value>";
    }
    switch (rule.trigger) {
      case "use-ai":
        return aiConfigurations.find((x) => x.id === Number(result))?.name;
      case "use-workflow-on-resolve":
        return workflows.find((x) => x.id === Number(result))?.name;
      case "use-prechat-survey":
      case "use-postchat-survey":
        return surveys.find((x) => x.id === Number(result))?.name;
      default:
        return result;
    }
  }

  return (
    <div
      onClick={editRule}
      className="flex flex-row gap-20 items-center border p-4 m-4 bg-slate-50 hover:bg-cornflower-blue-50 hover:border-cornflower-blue-100 cursor-pointer"
    >
      <span className="flex gap-4">
        <span>{rule.priority}.</span>
        <span>{triggerDefs[rule.trigger as keyof typeof triggerDefs]}:</span>
        <span className="flex flex-col italic">
          {Object.keys(rule.value).map(
            (k) => `${getRuleValueLabel(k, rule.value)}`
          )}
        </span>
        <span className="font-bold">
          {rule.expression && rule.expression.length > 0 ? "WHEN" : "ALWAYS"}
        </span>
        <span className="flex flex-col">
          {getExpressionLabel(rule.expression, getTeamNameById)}
        </span>
      </span>
      <span className="flex flex-col">
        <SecondaryBlueButton
          onClick={(e) => {
            e.stopPropagation();
            remove(rule);
          }}
          label="Delete"
        />
      </span>
    </div>
  );
}
