import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "@velaro/velaro-component-lib";
import useApi from "@/hooks/useApi";
import React, { useState } from "react";
import TeamForm, { defaultTeamModel, TeamModel } from "./TeamForm";
import LoadingSpinner from "@/components/LoadingSpinner";
import useToastAlert from "@/hooks/useToastAlert";
import { Severity } from "@velaro/velaro-component-lib";
import { PrimaryBlueButton } from "@velaro/velaro-component-lib";
import { Team } from "./types";

interface Props {
  teamId: number;
  onClose(): void;
  onTeamUpdated(team: Team): void;
}

export default function EditTeamModal(props: Props) {
  const api = useApi();
  const toastAlert = useToastAlert();
  const [team, setTeam] = React.useState<TeamModel>(defaultTeamModel);
  const [sending, setSending] = useState(false);
  const [success, setSuccess] = useState<boolean | undefined>(undefined);
  const [error, setError] = useState("");

  React.useEffect(() => {
    async function fetchTeam() {
      const response = api.messaging.get(`Teams/${props.teamId}`);
      setTeam(await (await response).json());
    }

    fetchTeam();
  }, [api.messaging, props.teamId]);

  async function handleSubmit() {
    setSending(true);
    const response = await api.messaging.put(`Teams/${props.teamId}`, team);
    if (response.ok) {
      const newTeam = await response.json();
      props.onTeamUpdated(newTeam);
      toastAlert.displayToast(Severity.Success, "Team created successfully");
    } else {
      const error = await response.json();
      setError(error);
    }
    setSending(false);
  }

  return (
    <Modal show={true}>
      <ModalHeader title="Edit Team" onClose={() => props.onClose()} />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <ModalBody>
          <div className="px-4">
            <TeamForm
              team={team}
              onChange={(updates) => {
                setTeam((oldState) => ({ ...oldState, ...updates }));
              }}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="p-4 flex flex-col">
            {error != "" && (
              <div className="flex flex-row mb-4 text-red-500">{error}</div>
            )}
            {sending ? (
              <div className="ml-auto flex">
                Saving Changes...
                <LoadingSpinner />
              </div>
            ) : (
              <div className="ml-auto flex flex-row">
                <PrimaryBlueButton
                  onClick={() => handleSubmit()}
                  label="Save Changes"
                />
              </div>
            )}
          </div>
        </ModalFooter>
      </form>
    </Modal>
  );
}
