import { SurveysContext } from "@/context/SurveysContext";
import useApi from "@/hooks/useApi";
import { Survey } from "@/pages/Messaging/Surveys/types";
import { LoadingSpinner } from "@velaro/velaro-component-lib";
import React, { useCallback, useEffect, useState } from "react";

export function SurveysProvider(props: { children: React.ReactNode }) {
  const api = useApi();

  const [loading, setLoading] = useState(true);
  const [surveys, setSurveys] = useState<Survey[]>([]);

  const fetch = useCallback(async () => {
    const response = await api.messaging.get("Surveys/List");
    setSurveys(await response.json());
    setLoading(false);
  }, [api.messaging]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <SurveysContext.Provider
      value={{ surveys: surveys, loading: loading, setSurveys }}
    >
      {props.children}
    </SurveysContext.Provider>
  );
}
