import React, { useState } from "react";
import { PrimaryBlueButton } from "@velaro/velaro-component-lib";

export default function AIChatbotSettingsTrainingTabSiteMap() {
  const [trainingUrl, setTrainingUrl] = useState("");

  return (
    <>
      <input
        type="text"
        value={trainingUrl}
        onChange={(e) => setTrainingUrl(e.target.value)}
        placeholder="Enter a url"
        className="w-full p-2 border-[1px] rounded-md text-sm my-4"
      />

      <div className="flex justify-end">
        <PrimaryBlueButton
          label="Next"
          disabled={trainingUrl.length === 0}
          onClick={() => console.log("button clicked")}
        />
      </div>
    </>
  );
}
