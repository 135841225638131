import Select from "@/components/Select";
import useWorkflows from "@/hooks/useWorkflows";
import React from "react";
import { Link } from "react-router-dom";

export interface WorkflowOnResolveValue {
  workflowId: number;
}

interface Props {
  updateValue: (value: WorkflowOnResolveValue) => void;
  value: WorkflowOnResolveValue;
}

export default function WorkflowOnResolveRuleForm({
  value,
  updateValue
}: Props) {
  const { workflows } = useWorkflows();
  if (!workflows || !workflows.length) {
    return (
      <>
        <span>{"You don't have any workflows yet."}</span>
        <Link to="/workflows">
          <button type="button" className="btn-link">
            Create one now.
          </button>
        </Link>
      </>
    );
  }

  return (
    <>
      <div>
        Set the conditions for when a workflow should run on conversation
        resolve
      </div>
      <div className="flex flex-col mr-auto">
        <div>
          Select your workflow:
          <Select
            onChange={(workflowId) =>
              updateValue({ workflowId: Number(workflowId) })
            }
            options={workflows.map((w) => ({
              label: w.name,
              value: w.id
            }))}
            value={value.workflowId}
          />
        </div>
      </div>
    </>
  );
}
