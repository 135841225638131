import useApi from "@/hooks/useApi";
import React, { useCallback, useEffect, useState } from "react";
import { RulesContext } from "@/context/RulesContext";
import { Rule } from "@/pages/Messaging/Rules/types";

export function RulesProvider(props: { children: React.ReactNode }) {
  const api = useApi();
  const [rules, setRules] = useState<Rule[]>([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  const fetch = useCallback(async () => {
    setLoading(true);
    const json = await api.messaging.get("Rules/List");
    const result = (await json.json()) as Rule[];

    setRules(result);
    setLoading(false);
  }, [api.messaging]);

  async function remove(rule: Rule) {
    const clone = [...rules];

    if (rule.id === undefined) {
      //hasn't been saved yet
      setRules(clone);
      return;
    }

    const i = rules.findIndex((x) => x.id === rule.id);
    clone.splice(i, 1)[0];

    try {
      const res = await api.messaging.delete("Rules", rule);
      if (res.ok) {
        setRules(clone);
      } else {
        console.error(res.statusText);
      }
    } catch (e) {
      console.error(e);
    }
  }

  async function add(rule: Rule) {
    setSaving(true);
    try {
      const res = await api.messaging.post("Rules", rule);
      if (res.ok) {
        const data = await res.json();
        setRules([...rules, data]);
      } else {
        console.error(res.statusText);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setSaving(false);
    }
  }

  async function update(rule: Rule) {
    setSaving(true);
    try {
      const res = await api.messaging.put("Rules", rule);
      if (res.ok) {
        const data = await res.json();
        const clone = [...rules];
        const i = rules.findIndex((x) => x.id === rule.id);
        clone[i] = data;
        setRules(clone);
      } else {
        console.error(res.statusText);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setSaving(false);
    }
  }

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <RulesContext.Provider
      value={{ rules: rules, saving, loading, add, update, remove }}
    >
      {props.children}
    </RulesContext.Provider>
  );
}
