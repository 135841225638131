import useApi from "@/hooks/useApi";
import * as React from "react";
import { useMemo, useState } from "react";
import { Trigger, WorkflowListItem, triggerDefs } from "./types";
import IllustrationMyWorkflows from "@/icons/illustrations/IllustrationMyWorkflows";
import IconChevronDown from "@/icons/IconChevronDown";
import IconChevronRight from "@/icons/IconChevronRight";
import { Checkbox, WorkflowItem } from "./WorkflowHelpers";
import useWorkflows from "@/hooks/useWorkflows";

export default function Workflows() {
  const { workflows, setWorkflows } = useWorkflows();
  const [collapsedTriggers, setCollapsedTriggers] = useState<Trigger[]>([]);
  const api = useApi();

  const groupedWorkflows = useMemo(() => {
    if (!workflows) {
      return {};
    }
    const result: Record<string, WorkflowListItem[]> = {};
    //group based on triggerType property
    workflows.forEach((workflow) => {
      if (!result[workflow.triggerType]) {
        result[workflow.triggerType] = [];
      }
      result[workflow.triggerType].push(workflow);
    });

    return result;
  }, [workflows]);

  const triggers = useMemo(() => {
    if (!workflows) {
      return [];
    }
    return Object.keys(groupedWorkflows) as Trigger[];
  }, [groupedWorkflows, workflows]);

  if (workflows.length === 0) {
    return (
      <div className="p-16 bg-white rounded-2xl border border-slate-200 flex-col justify-center items-center gap-4 inline-flex">
        <div className="flex-col justify-center items-center gap-6 flex">
          <IllustrationMyWorkflows />
          <div className="flex-col justify-center items-center gap-2 flex">
            <div className="text-slate-800 text-lg">My workflows</div>
            <div className="text-center text-slate-500 text-sm">
              Your workflow list is currently empty.
              <br />
              Build your first workflow to enhance customer experiences and
              automate your processes.
            </div>
          </div>
        </div>
      </div>
    );
  }

  async function toggleWorkflow(id: number) {
    if (!workflows) return;

    await api.messaging.post(`Workflows/toggle/${id}`);

    const index = workflows.findIndex((w) => w.id === id);
    const workflow = workflows[index];
    const clone = [...workflows];
    clone[index] = { ...workflow, enabled: !workflow.enabled };
    setWorkflows(clone);
  }

  function toggleTrigger(trigger: Trigger) {
    if (collapsedTriggers.includes(trigger)) {
      setCollapsedTriggers(collapsedTriggers.filter((t) => t !== trigger));
    } else {
      setCollapsedTriggers([...collapsedTriggers, trigger]);
    }
  }

  async function removeWorkflow(id: number) {
    if (workflows === null) return;

    await api.messaging.delete(`Workflows/${id}`);
    const clone = workflows?.filter((w) => w.id !== id);
    setWorkflows(clone);
  }

  function renderTrigger(trigger: Trigger, index: number) {
    const isCollapsed = collapsedTriggers.includes(trigger);
    return (
      <div className="p-4" key={index}>
        <div className="mb-4 flex">
          <div
            onClick={() => toggleTrigger(trigger)}
            className="flex gap-2 items-center font-bold cursor-pointer"
          >
            {isCollapsed ? <IconChevronRight /> : <IconChevronDown />}
            {triggerDefs[trigger]}
          </div>
        </div>
        {!isCollapsed && (
          <div className="border rounded-lg border-slate-200">
            <table cellPadding={10} className="w-full text-left">
              <thead>
                <tr className="border-b border-slate-200 h-12">
                  <th className="w-1/12">
                    <div className="flex items-center">
                      <Checkbox />
                    </div>
                  </th>
                  <th className="w-1/4">Name</th>
                  <th className="w-1/4">Created by</th>
                  <th className="w-1/6">Updated</th>
                  <th className="w-1/6">Status</th>
                  <th className="w-1/12"></th>
                </tr>
              </thead>
              <tbody>
                {groupedWorkflows[trigger].map((workflow, i) => (
                  <WorkflowItem
                    key={i}
                    item={workflow}
                    isLast={i === groupedWorkflows[trigger].length - 1}
                    toggle={() => toggleWorkflow(workflow.id)}
                    remove={() => removeWorkflow(workflow.id)}
                  />
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  }

  return <>{triggers.map(renderTrigger)}</>;
}
