import { ToastAlert, Severity } from "@velaro/velaro-component-lib";
import ToastAlertContext from "@/context/ToastAlertContext";
import { ToastAlertSettings } from "../types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import useApi from "@/hooks/useApi";
import { createRoot } from "react-dom/client";
import { LoadingSpinner } from "@velaro/velaro-component-lib";

function closeAlert(id: string) {
  const uniqueToastContainer = document.getElementById(id);
  if (uniqueToastContainer === null) {
    console.log("Toast container not found");
    return;
  }
  uniqueToastContainer.remove();
}

export default function ToastAlertProvider(props: {
  children: React.ReactNode;
}) {
  const [toastSettings, setToastSettings] = useState<ToastAlertSettings>({
    stickyAlerts: false,
    stickyDuration: 5000
  });
  const [loading, setLoading] = useState(true);
  const api = useApi();

  useEffect(() => {
    async function fetchSettings() {
      const resp = await api.messaging.get("NotificationSettings");
      setToastSettings(await resp.json());
      setLoading(false);
    }

    fetchSettings();
  }, [api.messaging]);

  const alertsContainer = useRef<HTMLDivElement>(null);

  const displayToast = useCallback(
    (severity: Severity, message: string) => {
      const r = (Math.random() + 1).toString(36).substring(7);
      const newElement = document.createElement("div");
      newElement.id = r;
      const container = alertsContainer.current;
      if (container === null) {
        return;
      }
      container.appendChild(newElement);
      const root = createRoot(newElement);
      root.render(
        <ToastAlert
          severity={severity}
          message={message}
          sticky={toastSettings.stickyAlerts}
          stickyDuration={toastSettings.stickyDuration}
          id={r}
          closeAlert={closeAlert}
        />
      );
    },
    [toastSettings.stickyAlerts, toastSettings.stickyDuration]
  );

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <ToastAlertContext.Provider
      value={{ displayToast, toastSettings, loading }}
    >
      <div
        id="alerts-container"
        className="fixed flex flex-col justify-center items-end top-20 w-full pr-8 pointer-events-none z-50"
        ref={alertsContainer}
      ></div>
      {props.children}
    </ToastAlertContext.Provider>
  );
}
