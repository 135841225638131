import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "@velaro/velaro-component-lib";
import useApi from "@/hooks/useApi";
import React, { useState } from "react";
import { SingleValue } from "react-select";
import Option from "react-select/dist/declarations/src/components/Option";
import { NetSuiteMap } from "./NetSuiteMappingTable";
interface Option {
  value: any;
  label: string;
  key?: string;
  disabled?: boolean;
}

interface Props {
  netSuiteMap: NetSuiteMap;
  onClose(): void;
  saveLinkedField(field: NetSuiteMap): void;
}

export default function NetSuiteModal(props: Props) {
  const api = useApi();
  const [selected, setSelected] = useState("");
  const [linkedField, setLinkedField] = useState("");
  const [sentLinkedField, setSentLinkedField] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(-1);

  function renderButton(title: string, label: string, index: number) {
    const isSelected = selectedIndex === index ? true : false;
    const bgColor = isSelected
      ? "bg-cornflower-blue-500"
      : "bg-cornflower-blue-400";

    const handleClick = () => {
      setSelectedIndex(index);
      setSelected(label);
    };

    return (
      <div className={`${bgColor} px-4 py-2`} onClick={() => handleClick()}>
        {title}
      </div>
    );
  }

  function handleLinkedField(selectedType: string, field: string) {
    const newField = { table: selectedType, column: field };
    setLinkedField(field);
    setSentLinkedField(JSON.stringify(newField));
  }

  return (
    <Modal show={true} maxWidth="max-w-3xl">
      <ModalHeader
        title={
          "Link " + props.netSuiteMap?.netSuiteField?.value + " To Velaro Field"
        }
        onClose={() => props.onClose()}
      />
      <ModalBody>
        <div className="flex">
          <div className="flex flex-none flex-col border-r bg-cornflower-blue-400 text-sm text-slate-50 max-w-max">
            <div className="flex flex-col overflow-y-auto">
              {renderButton("Visitor Monitoring", "Monitoring", 1)}
              {renderButton("Velaro Bot", "Bot", 2)}
              {renderButton("Prechat Surveys", "Prechat", 3)}
              {renderButton("Missed Chat Survey", "Missed", 4)}
              {renderButton("Contacts", "Contacts", 5)}
            </div>
          </div>
          {selected === "Monitoring" && (
            <div className="flex flex-col px-4 py-2">
              <select
                className="w-full h-max focus:outline-none p-2"
                value={linkedField}
                onChange={() => setLinkedField}
              >
                <option>Monitoring 1</option>
                <option>Monitoring 2</option>
                <option>Monitoring 3</option>
                <option>Monitoring 4</option>
              </select>
            </div>
          )}
          {selected === "Bot" && (
            <div className="flex flex-col px-4 py-2">
              <select
                className="w-full h-max focus:outline-none p-2"
                value={linkedField}
                onChange={() => setLinkedField}
              >
                <option>Bot 1</option>
                <option>Bot 2</option>
                <option>Bot 3</option>
                <option>Bot 4</option>
              </select>
            </div>
          )}
          {selected === "Contacts" && (
            <div className="flex flex-col px-4 py-2">
              <select
                className="w-full h-max focus:outline-none p-2"
                value={linkedField}
                onChange={(e) =>
                  handleLinkedField(selected, e.currentTarget.value)
                }
              >
                <option></option>
                <option>Email</option>
                <option>Name</option>
              </select>
            </div>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="flex items-center p-3">
          <div className="ml-auto">
            <button
              className="btn btn-green"
              onClick={() => {
                props.netSuiteMap.linkedTo = sentLinkedField;
                props.saveLinkedField(props.netSuiteMap);
                props.onClose();
              }}
            >
              Ok
            </button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
}
