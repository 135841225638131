import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "@velaro/velaro-component-lib";
import useApi from "@/hooks/useApi";
import React, { useMemo, useState } from "react";
import { BotProfile } from "./types";
import LoadingSpinner from "@/components/LoadingSpinner";
import { PrimaryBlueButton } from "@velaro/velaro-component-lib";
import useSite from "@/hooks/useSite";
import { FormGroup } from "@velaro/velaro-component-lib";

interface Props {
  botProfile?: BotProfile;
  onClose(): void;
  onSave(profile: BotProfile): void;
}

const defaultPictureUrl =
  "https://velaromessagingstaging.blob.core.windows.net/images/default-bot-pfp.png";

export default function EditBotProfileModal({
  botProfile,
  onClose,
  onSave
}: Props) {
  const { site } = useSite();
  const [name, setName] = useState(
    botProfile?.name || site.companyName + " Bot"
  );
  const [picture, setPicture] = useState<File>();
  const [sending, setSending] = useState(false);
  const [success, setSuccess] = useState<boolean | undefined>(undefined);
  const api = useApi();

  const picturePreviewUrl = useMemo(() => {
    if (picture) {
      return URL.createObjectURL(picture);
    }

    if (botProfile?.picture) {
      return botProfile.picture;
    } else {
      return defaultPictureUrl;
    }
  }, [botProfile, picture]);

  async function save() {
    setSending(true);
    const formData = new FormData();

    const id = botProfile?.id || 0;
    formData.append("Id", id.toString());
    formData.append("name", name);
    if (picture) {
      formData.append("picture", picture);
    }

    const resp = await api.messaging.postFormData("BotProfiles", formData);
    setSuccess(resp.ok);
    if (resp.ok) {
      const result = await resp.json();
      onSave(result);
    } else {
      alert("error");
    }
    setSending(false);
  }
  const title = botProfile ? "Edit Bot Profile" : "Add Bot Profile";
  return (
    <Modal maxWidth={"max-w-xl"} show={true}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          save();
        }}
      >
        <ModalHeader title={title} onClose={() => onClose()} />
        <ModalBody>
          <div className="flex gap-4 items-center justify-center">
            <div>
              <FormGroup label="Name">
                <input
                  type="text"
                  className="input w-full"
                  value={name}
                  max={50}
                  onChange={(e) => setName(e.currentTarget.value)}
                />
              </FormGroup>
              <FormGroup label="Headshot">
                <input
                  type="file"
                  className="input w-full"
                  accept="image/*"
                  required
                  onChange={(e) => {
                    if (!e.currentTarget.files) {
                      return;
                    }

                    setPicture(e.currentTarget.files[0]);
                  }}
                />
              </FormGroup>
            </div>
            <div className="flex flex-col justify-center items-center w-20">
              <img
                className="h-12 w-12 rounded-full border group-hover:border-2"
                src={picturePreviewUrl}
                alt=""
              ></img>
              <span className="overflow-hidden w-full overflow-ellipsis whitespace-nowrap">
                {name}
              </span>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="p-4 flex">
            {success === false && (
              <div className="flex flex-row mb-4">
                There was an error saving your changes. Please see Integration
                Log for more details.
              </div>
            )}
            {sending ? (
              <div className="ml-auto flex">
                Saving Changes...
                <LoadingSpinner />
              </div>
            ) : (
              <div className="ml-auto flex">
                <PrimaryBlueButton
                  onClick={() => save()}
                  label="Save Changes"
                />
              </div>
            )}
          </div>
        </ModalFooter>
      </form>
    </Modal>
  );
}
