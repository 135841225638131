import { Survey } from "@/pages/Messaging/Surveys/types";
import React from "react";

export const SurveysContext = React.createContext<{
  surveys: Survey[];
  setSurveys: (surveys: Survey[]) => void;
  loading: boolean;
}>({
  surveys: [],
  loading: true,
  setSurveys: () => {}
});
