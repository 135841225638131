import React, { useEffect, useState } from "react";
import useAIConfigs from "@/hooks/useAIConfigs";
import NewAILanding from "./NewAILanding";
import {
  LoadingSpinner,
  TableStyle,
  tableStyles
} from "@velaro/velaro-component-lib";
import { SortableTable } from "@velaro/velaro-component-lib";
import AIConfigurationTablePopover from "./AIConfigurationTablePopover";

export default function AI() {
  const { aiConfigurations, loading } = useAIConfigs();
  const [tableData, setTableData] = useState(aiConfigurations);

  useEffect(() => {
    setTableData(aiConfigurations);
  }, [aiConfigurations]);

  //Todo translate
  const columns = [
    { label: "Name", accessor: "name" },
    { label: "AI Model", accessor: "aiModel" },
    { label: "Metrics", accessor: "metrics" },
    { label: "Updated", accessor: "updated" },
    { label: "Status", accessor: "status" },
    { label: "", accessor: "edit" }
  ];

  if (loading) return <LoadingSpinner />;

  //Todo translate
  if (aiConfigurations.length === 0) return <NewAILanding />;

  return (
    <SortableTable
      columns={columns}
      tableData={tableData}
      setTableData={setTableData}
      popoverComponent={AIConfigurationTablePopover}
      style={tableStyles.half as TableStyle}
    />
  );
}
