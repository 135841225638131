import * as React from "react";
import { WorkflowPropValue, WorkflowNode } from "../../types";
import { FormGroup, ToggleGroup } from "../RightAsideHelpers";
import Input from "@/components/Input";
import { TeriaryGrayButton } from "@velaro/velaro-component-lib";
import { IconPlus } from "@velaro/velaro-component-lib";
import { IconClose } from "@velaro/velaro-component-lib";

interface Props {
  node: WorkflowNode;
  onUpdate: (node: Partial<WorkflowNode>) => void;
}

export default function SetDataEditor({ node, onUpdate }: Props) {
  return (
    <>
      <FormGroup label="Data to set">
        <div className="border rounded-lg p-2 mb-4 bg-slate-gray-100 flex flex-col items-center">
          {(node.dataToSet || []).map((data, i) => (
            <React.Fragment key={i}>
              <div className="flex w-full items-center gap-1">
                <Input
                  placeholder="Name"
                  className="my-2 flex grow"
                  value={data.name}
                  onChange={(name) => {
                    const newData = [...node.dataToSet];
                    newData[i].name = name;
                    onUpdate({ dataToSet: newData });
                  }}
                />
                <IconClose
                  onClick={() => {
                    onUpdate({
                      dataToSet: node.dataToSet.filter(
                        (_, index) => index !== i
                      )
                    });
                  }}
                  className="cursor-pointer"
                  style={{ color: "#CBD5E1" }}
                />
              </div>
              <Input
                placeholder="Value"
                className="my-2 flex grow"
                value={data.value}
                onChange={(value) => {
                  const newData = [...node.dataToSet];
                  newData[i].value = value;
                  onUpdate({ dataToSet: newData });
                }}
              />
              <div className="my-2 border border-slate-300 w-full"></div>
            </React.Fragment>
          ))}
          <TeriaryGrayButton
            label="Add Data"
            prefixIcon={<IconPlus />}
            className="w-full"
            onClick={() => {
              const newProp = {
                name: "",
                value: ""
              } as WorkflowPropValue;
              onUpdate({ dataToSet: [...(node.dataToSet || []), newProp] });
            }}
          />
        </div>
      </FormGroup>
      <FormGroup label="Save as">
        <ToggleGroup
          toggled={node.saveAsContactProp || false}
          label="Contact Property"
          changeToggle={() => {
            onUpdate({
              saveAsContactProp: !node.saveAsContactProp
            });
          }}
        />
        <ToggleGroup
          toggled={node.saveAsConversationProp || false}
          label="Conversation Property"
          changeToggle={() => {
            onUpdate({
              saveAsConversationProp: !node.saveAsConversationProp
            });
          }}
        />
        <ToggleGroup
          toggled={node.saveAsWorkflowProp || false}
          label="Workflow Property"
          changeToggle={() => {
            onUpdate({
              saveAsWorkflowProp: !node.saveAsWorkflowProp
            });
          }}
        />
      </FormGroup>
    </>
  );
}
