import React, { useCallback, useEffect, useRef, useState } from "react";
import AIConfigurationTablePopoverRow from "./AIConfigurationTablePopoverRow";
import { IconEdit } from "@velaro/velaro-component-lib";
import { IconCopy } from "@velaro/velaro-component-lib";
import { IconTrash } from "@velaro/velaro-component-lib";
import { Link } from "react-router-dom";
import useAIConfigs from "@/hooks/useAIConfigs";
import { AIConfiguration } from "../types";

interface Column {
  label: string;
  accessor: string;
}

interface Props {
  // columns: Column[];
  // tableData: AIConfiguration[];
  id: number;
  setVisible(visible: boolean): void;
}

export default function AIConfigurationTablePopover(props: Props) {
  const popoverRef = useRef<HTMLDivElement>(null);
  const { aiConfigurations, add, update, remove } = useAIConfigs();
  const [config, setConfig] = useState<AIConfiguration>();
  const [configIndex, setConfigIndex] = React.useState<number>();
  const handleDuplicate = () => {
    console.log("Duplicate");
  };
  useEffect(() => {
    if (aiConfigurations.length > 0 && props.id) {
      const configIndex = aiConfigurations.findIndex(
        (config) => config.id === props.id
      );
      if (configIndex != null && configIndex != undefined) {
        setConfig(aiConfigurations[configIndex]);
        setConfigIndex(configIndex);
      }
    }
  }, [aiConfigurations, props.id]);

  const handleDelete = () => {
    if (config && configIndex != null && configIndex != undefined)
      remove(config, configIndex);
  };

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target as Node)
      ) {
        props.setVisible(false);
      }
    },
    [props]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <div
      className="p-2 rounded-md border-[1px] shadow-md bg-white z-10 absolute right-4 w-[145px]"
      ref={popoverRef}
    >
      <Link to={`/ai/settings/general/${props.id}`}>
        <AIConfigurationTablePopoverRow
          icon={<IconEdit className="hover:stroke-cornflower-blue-500" />}
          text="Edit"
          action={() => {}}
        />
      </Link>
      <AIConfigurationTablePopoverRow
        icon={<IconCopy className="hover:stroke-cornflower-blue-500" />}
        text="Duplicate"
        action={handleDuplicate}
      />
      <AIConfigurationTablePopoverRow
        icon={<IconTrash className="hover:stroke-cornflower-blue-500" />}
        text="Delete"
        action={handleDelete}
      />
    </div>
  );
}
