import * as React from "react";
import useModelState from "../hooks/useModelState";
import { FormGroup } from "@velaro/velaro-component-lib";
import useApi from "@/hooks/useApi";

export default function Index() {
  const [saving, setSaving] = React.useState(false);
  const [companyName, setCompanyName] = React.useState("");
  const [modelState, setModelState] = useModelState();
  const api = useApi();

  async function handleSubmit() {
    setSaving(true);

    const resp = await api.main.post("KBTenants", companyName);

    //todo wtf is this
    // App.site.set("KnowledgeBaseInstalled", true);
    window.location.href = "/kb/articles";
    setSaving(false);

    // if (err.status === 400) {
    //   setModelState(err.responseJSON.ModelState);
    // } else {
    //   alert(App.translate("app/kb/error"));
    //   console.error(err);
    // }
  }

  return (
    <div className="kb-container" style={{ maxWidth: 400 }}>
      <div className="kb-header">
        <h1>KB Setup</h1>
        {/* <h1>{App.translate("app/kb/kbsetup")}</h1> */}
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <FormGroup label="Company Name">
          {/* <label htmlFor="company-name">
            {App.translate("app/kb/companyname")}
          </label> */}
          <input
            type="text"
            id="company-name"
            className="form-control"
            value={companyName}
            onChange={(e) => setCompanyName(e.currentTarget.value)}
          />
        </FormGroup>
        <div>
          <button type="submit" className="btn btn-success" disabled={saving}>
            {saving ? "Creating KB..." : "Create KB"}
            {/* ? App.translate("app/kb/creatingkb")
              : App.translate("app/kb/createkb")} */}
          </button>
        </div>
      </form>
    </div>
  );
}
