import * as React from "react";
import useModelState from "../hooks/useModelState";
import { Category } from "../types";
import useApi from "@/hooks/useApi";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "@velaro/velaro-component-lib";
import { FormGroup } from "@velaro/velaro-component-lib";

interface Props {
  displayPriority: number;
  onClose(): void;
  onSubmit(category: Category): void;
}

export default function CategoryCreateModal(props: Props) {
  const [saving, setSaving] = React.useState(false);
  const [name, setName] = React.useState("");
  const [modelState, setModelState] = useModelState();
  const api = useApi();

  const handleSubmit = React.useCallback(async () => {
    setSaving(true);

    const response = await api.main.post("KBCategories", {
      name,
      displayPriority: props.displayPriority
    });
    const json = await response.json();

    props.onSubmit(json);
    if (response.status === 400) {
      console.error(response);
    }

    setSaving(false);
  }, [name, props, setModelState]);

  return (
    <Modal show={true}>
      <ModalHeader title="New Category" onClose={() => handleSubmit()} />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <ModalBody>
          <FormGroup label="Name">
            {/* <label htmlFor="category-name">Name</label> */}
            <input
              autoFocus
              id="category-name"
              type="text"
              className="form-control"
              value={name}
              onChange={(e) => setName(e.currentTarget.value)}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-default"
            onClick={() => props.onClose()}
          >
            Cancel
          </button>
          <button type="submit" className="btn btn-success" disabled={saving}>
            Create Category
          </button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
