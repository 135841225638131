import React from "react";
export const AutomationsContext = React.createContext<{
  automations: Record<string, string> | null;
  loading: boolean;
  fetchAutomations: () => Promise<void>;
}>({
  automations: null,
  loading: false,
  fetchAutomations: () => Promise.resolve()
});
