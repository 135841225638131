import Select from "@/components/Select";
import useSurveys from "@/hooks/useSurveys";
import React from "react";
import { Link } from "react-router-dom";

export interface SurveyRuleFormValue {
  surveyId: number;
}

interface Props {
  label: string;
  updateValue: (value: SurveyRuleFormValue) => void;
  value: SurveyRuleFormValue;
}

export default function SurveyRuleForm({ label, value, updateValue }: Props) {
  const { surveys } = useSurveys();
  if (!surveys || !surveys.length) {
    return (
      <>
        <span>{"You don't have any surveys yet."}</span>
        <Link to="/surveys">
          <button type="button" className="btn-link">
            Create one now.
          </button>
        </Link>
      </>
    );
  }

  return (
    <>
      <div>{label}</div>
      <div className="flex flex-col mr-auto">
        <div>
          Select your survey:
          <Select
            onChange={(surveyId) => updateValue({ surveyId: Number(surveyId) })}
            options={surveys.map((w) => ({
              label: w.name,
              value: w.id
            }))}
            value={value.surveyId}
          />
        </div>
      </div>
    </>
  );
}
