import React, { useMemo, useState } from "react";
import StyleForm from "../components/StyleForm";
import { StyleConfigModel } from "../../types";
import useStyleConfigs from "../hooks/useStyleConfigs";
import AddStyleConfigModal from "./AddStyleConfigModal";
import { PrimaryBlueButton } from "@velaro/velaro-component-lib";

export interface Props {
  selectedConfigId: number;
  setSelectedConfigId: (id: number) => void;
  setWorkingConfig: (config: StyleConfigModel) => void;
  workingConfig: StyleConfigModel;
}

export default function StyleConfig(props: Props) {
  const [showDeployModal, setShowDeployModal] = React.useState(false);
  const { styleConfigs, setStyleConfigs } = useStyleConfigs();
  const [showAddStyleConfigModal, setShowAddStyleConfigModal] = useState(false);

  const selectedConfig = useMemo(() => {
    return (
      styleConfigs?.find((x) => x.id === props.selectedConfigId) ||
      ({} as StyleConfigModel)
    );
  }, [styleConfigs, props.selectedConfigId]);

  function handleConfigChange(configId: number) {
    props.setSelectedConfigId(configId);
    props.setWorkingConfig(
      styleConfigs.find((x) => x.id === configId) || ({} as StyleConfigModel)
    );
  }

  return (
    <>
      <div className="panel bg-white rounded-b-md rounded-tr-md shadow-md p-4 w-full">
        <div className="w-full mt-4 mb-4 flex justify-between">
          <select
            className="w-1/2 border border-gray-300 rounded-md p-2"
            value={props.selectedConfigId}
            onChange={(e) => {
              handleConfigChange(parseInt(e.target.value));
            }}
          >
            {styleConfigs.map((config) => (
              <option key={config.id} value={config.id}>
                {config.name}
              </option>
            ))}
          </select>
          <div className="ml-4">
            <PrimaryBlueButton
              label="Add Style Config"
              onClick={() => setShowAddStyleConfigModal(true)}
            />
          </div>
        </div>
        <div className="overflow-auto">
          <div className="flex">
            <div className="flex-1">
              <StyleForm
                selectedConfig={selectedConfig}
                setWorkingConfig={props.setWorkingConfig}
                workingConfig={props.workingConfig}
              />
            </div>
          </div>
        </div>
      </div>
      {showAddStyleConfigModal && (
        <AddStyleConfigModal
          close={() => setShowAddStyleConfigModal(false)}
          setSelectedConfigId={props.setSelectedConfigId}
          setWorkingConfig={props.setWorkingConfig}
        />
      )}
    </>
  );
}
