import Select from "@/components/Select";
import React from "react";
import { IconClose } from "@velaro/velaro-component-lib";
import { conditionDefs, conditionOptions } from "./conditions";
import { ExpressionType, SerializedCondition } from "./types";

export interface ConditionBlockProps {
  expressionType: ExpressionType;
  condition: SerializedCondition;
  onUpdate: (condition: SerializedCondition) => void;
  onRemove?: () => void;
}

export function ConditionBlock({
  expressionType,
  condition,
  onUpdate,
  onRemove
}: ConditionBlockProps) {
  const conditionDef = conditionDefs[condition.type];

  const Editor = conditionDef?.editor;

  const validConditions = conditionOptions.filter((option) => {
    const definition = conditionDefs[option.value];

    if (definition.whitelist) {
      return definition.whitelist.includes(expressionType);
    }

    if (definition.blacklist) {
      return !definition.blacklist.includes(expressionType);
    }

    return true;
  });

  return (
    <div className="flex flex-col gap-4 px-3 py-4 bg-white rounded-lg border border-slate-30 justify-center items-start">
      <div className="w-full flex gap-2 items-center justify-between">
        <Select
          options={validConditions}
          value={condition.type}
          onChange={(v) => onUpdate({ ...condition, type: v })}
        />
        <IconClose
          onClick={onRemove}
          className="cursor-pointer"
          style={{ color: "#CBD5E1" }}
        />
      </div>
      {Editor && <Editor condition={condition} onUpdate={onUpdate} />}
    </div>
  );
}
