import React from "react";
import { AppRoutes } from "./routes/Routes";
import { AuthProvider } from "./providers/AuthProvider";
import useWarmup from "./hooks/useWarmup";
import { Warmup } from "@velaro/velaro-component-lib";

function App() {
  const { warmedUp } = useWarmup();
  if (!warmedUp) {
    return <Warmup />;
  }
  return (
    <AuthProvider>
      <AppRoutes />
    </AuthProvider>
  );
}

export default App;
