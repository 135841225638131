import { Card } from "@/components/Card";
import React, { useState } from "react";
import Select from "@/components/Select";
import { applications } from "./Applications";

const appCategories = [
  { value: "all", label: "All Apps" },
  { value: "my-apps", label: "My Apps" },
  { value: "pending", label: "Pending" }
];

export default function Landing() {
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [visibleCount, setVisibleCount] = useState(11);

  const filteredApps =
    selectedCategory === "all"
      ? applications
      : applications.filter((app) => app.category === selectedCategory);

  React.useEffect(() => {
    setVisibleCount(11);
  }, [selectedCategory]);

  const handleLoadMore = () => {
    setVisibleCount((prevCount) => prevCount + 6);
  };
  return (
    <div className="panel bg-white dark:bg-stone-300 rounded-md shadow-md mb-4 p-4">
      <div className="flex justify-between items-center mb-4">
        <div className="text-center text-slate-800 text-lg font-medium font-['Rubik'] leading-7">
          All Apps
          <span className="text-center text-slate-500 text-lg font-medium font-['Rubik'] leading-7 ml-2">
            {filteredApps.length - 1} apps
          </span>
        </div>
        <style>{`select option[value=""] { display: none; }`}</style>
        <Select<string>
          className="w-48 h-12 text-sm px-3 py-2 border rounded-md !w-48"
          value={selectedCategory}
          options={appCategories}
          onChange={setSelectedCategory}
        />
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 netsuite-card">
        {filteredApps.slice(0, visibleCount).map((app, index) => (
          <Card
            key={app.id}
            to={`/integrations/${app.id}`}
            title={app.name}
            subtitle={app.subTitle}
            icon={app.icon}
            variant="integrationCard"
            isFirstCard={index === 0}
          />
        ))}
      </div>

      {visibleCount < filteredApps.length && (
        <div className="flex justify-center mt-4">
          <button
            onClick={handleLoadMore}
            className="text-blue-600 font-medium focus:outline-none"
          >
            Load More
          </button>
        </div>
      )}
    </div>
  );
}
