import * as React from "react";
import { slugify } from "../helpers";
import useTopics from "../hooks/useTopics";
import HtmlEditor from "./HtmlEditor";
import TagEditor from "./TagEditor";
import { ArticleVisibility } from "../types";
import TopicCreateModal from "./TopicCreateModal";
import { FormGroup } from "@velaro/velaro-component-lib";

interface ArticleFormModel {
  visibility: ArticleVisibility;
  searchTags: string;
  content: string;
  description: string;
  topicId: string;
  slug: string;
  title: string;
  displayPriority: number;
}

interface Props {
  modelState: { [key: string]: string[] };
  article: ArticleFormModel;
  onChange(fields: Partial<ArticleFormModel>): void;
}

export default function ArticleForm(props: Props) {
  const [showTopicModal, setShowTopicModal] = React.useState(false);
  const { topics, addTopic } = useTopics();
  const modelState = props.modelState;

  return (
    <React.Fragment>
      <FormGroup label="Title">
        <>
          <input
            type="text"
            id="title"
            className="form-control"
            autoFocus
            value={props.article.title}
            onChange={(e) => {
              props.onChange({
                title: e.currentTarget.value,
                slug: slugify(e.currentTarget.value)
              });
            }}
          />
        </>
      </FormGroup>
      <FormGroup label="Slug">
        <input
          type="text"
          id="slug"
          className="form-control"
          value={props.article.slug}
          onChange={(e) =>
            props.onChange({
              slug: e.currentTarget.value
            })
          }
        />
      </FormGroup>
      <FormGroup label="Topic">
        <>
          <div className="kb-topic-label">
            {/* <label htmlFor="topic">{App.translate("app/kb/topic")}</label> */}
            <div className="actions">
              <button onClick={() => setShowTopicModal(true)}>
                New Topic
                {/* {App.translate("app/kb/newtopic")} */}
              </button>
            </div>
          </div>
          <select
            id="topic"
            className="form-control"
            value={props.article.topicId}
            onChange={(e) => props.onChange({ topicId: e.currentTarget.value })}
          >
            <option value="">Select Topic</option>
            {/* <option value="">{App.translate("app/kb/selecttopic")}</option> */}
            {topics.map((topic) => (
              <option key={topic.Id} value={topic.Id}>
                {topic.Name}
              </option>
            ))}
          </select>
        </>
      </FormGroup>
      <FormGroup label="Description">
        {/* <label htmlFor="description">
          {App.translate("app/kb/description")}
        </label> */}
        <input
          type="text"
          id="description"
          className="form-control"
          value={props.article.description}
          onChange={(e) =>
            props.onChange({ description: e.currentTarget.value })
          }
        />
      </FormGroup>
      <FormGroup label="Display Priority">
        {/* <label htmlFor="description">Display priority</label> */}
        <input
          type="number"
          id="description"
          className="form-control"
          value={props.article.displayPriority}
          onChange={(e) =>
            props.onChange({ displayPriority: parseInt(e.currentTarget.value) })
          }
        />
      </FormGroup>
      <FormGroup label="Content">
        {/* <label htmlFor="html-content">{App.translate("app/kb/content")}</label> */}
        <HtmlEditor
          value={props.article.content}
          onChange={(val: string) => props.onChange({ content: val })}
        />
      </FormGroup>
      <FormGroup label="SearchTags">
        {/* <label htmlFor="search-tags">Search tags</label> */}
        <TagEditor
          value={props.article.searchTags}
          onChange={(value) => props.onChange({ searchTags: value })}
        />
      </FormGroup>
      <FormGroup label="Visbility">
        <>
          <div className="radio">
            <label>
              <input
                type="radio"
                name="visibility"
                value={ArticleVisibility.Public}
                checked={props.article.visibility == ArticleVisibility.Public}
                onChange={(e) =>
                  props.onChange({
                    visibility: parseInt(e.currentTarget.value)
                  })
                }
              />
              Public
              {/* {App.translate("app/kb/public")} */}
            </label>
          </div>
          <div className="radio">
            <label>
              <input
                type="radio"
                name="visibility"
                value={ArticleVisibility.Internal}
                checked={props.article.visibility == ArticleVisibility.Internal}
                onChange={(e) =>
                  props.onChange({
                    visibility: parseInt(e.currentTarget.value)
                  })
                }
              />
              Internal
              {/* {App.translate("app/kb/internal")} */}
            </label>
          </div>
        </>
      </FormGroup>
      {showTopicModal && (
        <TopicCreateModal
          onClose={() => setShowTopicModal(false)}
          onSubmit={(topic) => {
            addTopic(topic);
            setShowTopicModal(false);
            props.onChange({ topicId: topic.Id });
          }}
        />
      )}
    </React.Fragment>
  );
}
