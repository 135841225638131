import React from "react";
import { Link } from "react-router-dom";

const styles = {
  integrationCard:
    "h-40 p-6 bg-white rounded-lg shadow-md border border-slate-300 flex flex-col justify-center gap-2 w-full",
  defaultCard:
    "border float-left rounded w-80 h-36 mr-4 mb-4 hover:shadow group"
};

export function Card({
  to,
  title,
  subtitle,
  icon,
  status,
  variant = "defaultCard",
  isFirstCard = false
}: {
  to: string;
  title: string;
  subtitle: string;
  icon?: string;
  status?: string;
  variant?: "defaultCard" | "integrationCard";
  isFirstCard?: boolean;
}) {
  if (variant === "integrationCard") {
    return (
      <div className="w-full mb-4">
        <Link to={to}>
          <div
            className={`h-45 p-6 bg-white rounded-lg shadow-md border border-slate-300  w-full ${
              isFirstCard
                ? "text-center content-center"
                : "grid grid-rows-[auto,1fr] gap-2"
            }`}
          >
            <div
              className={`font-semibold group flex items-center gap-2 ${
                isFirstCard ? "justify-center" : ""
              }`}
            >
              <>
                {
                  <div
                    className={`${
                      !isFirstCard
                        ? "w-14 h-14 border-slate-300 relative mr-4 rounded border p-1 content-center"
                        : ""
                    } `}
                  >
                    {icon && <img src={icon} alt={title} />}
                  </div>
                }
                <h3 className="font-semibold">{title}</h3>
              </>
            </div>
            <div className="text-sm pt-2">{subtitle}</div>
          </div>
        </Link>
      </div>
    );
  } else {
    return (
      <div className="w-80 h-36">
        <Link to={to}>
          <div className="border float-left rounded w-80 h-36 mr-4 mb-4 hover:shadow group">
            <div className="font-semibold mb-2 bg-cornflower-blue-400 group-hover:bg-cornflower-blue-500 text-slate-50 p-3 group">
              {title}
            </div>
            <div className="text-sm p-3">{subtitle}</div>
          </div>
        </Link>
      </div>
    );
  }
}
