import React from "react";
import { UploadedFile } from "./types";
import useToastAlert from "@/hooks/useToastAlert";
import { Severity } from "@velaro/velaro-component-lib";

interface Props {
  files: UploadedFile[];
  onRemove(file: UploadedFile): void;
}

export default function UploadedFileEditor(props: Props) {
  const { displayToast } = useToastAlert();
  if (!props.files.length) {
    return null;
  }

  function renderFile(file: UploadedFile, index: number) {
    let imageSrc: string | null = null;
    if (isFileImage(file)) {
      imageSrc = file.url;
    }

    return (
      <div
        title={file.fileName}
        className="border relative p-2 rounded w-36 h-40 flex flex-col gap-2 items-center justify-center"
        key={index}
      >
        {imageSrc && (
          <img
            className="h-full min-h-0 object-scale-down flex-grow"
            src={imageSrc}
            onLoad={() => {
              URL.revokeObjectURL(imageSrc!);
            }}
          />
        )}
        {!imageSrc && (
          <i className="fa fa-file fa-8x flex-grow h-full min-h-0" />
        )}
        <div className="text-sm text-center w-full overflow-hidden whitespace-nowrap h-6">
          {file.fileName}
        </div>
        <div
          style={{ top: -10, left: -10 }}
          className="bg-white absolute border shadow rounded z-10"
        >
          <button
            title={`Copy to clipboard`}
            className="w-8 h-8"
            onClick={() => {
              navigator.clipboard.writeText(file.url);
              displayToast(Severity.Info, "Copied to clipboard");
            }}
          >
            <i className="fa fa-light fa-copy"></i>
          </button>
        </div>
        <div
          style={{ top: -10, right: -10 }}
          className="bg-white absolute border shadow rounded z-10"
        >
          <button
            title={`Remove ${file.fileName}`}
            className="text-crimson-red-600 w-8 h-8"
            onClick={() => {
              props.onRemove(file);
            }}
          >
            <i className="fa fa-times"></i>
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-wrap cursor-default pb-4 mb-4 gap-5 pt-3">
      {props.files.map(renderFile)}
    </div>
  );
}

function isFileImage(file: UploadedFile) {
  const filename = file.fileName?.toLowerCase() || "";
  return (
    filename.endsWith(".jpg") ||
    filename.endsWith(".jpeg") ||
    filename.endsWith(".png")
  );
}
