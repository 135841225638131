import * as React from "react";
import { Survey } from "./types";
import QuestionEditor from "./QuestionEditor";
import {
  IconPlus,
  IconTrash,
  SecondaryBlueButton,
  TeriaryBlueButton
} from "@velaro/velaro-component-lib";

interface Props {
  survey: Survey;
  errors: Record<string, string>[];
  update(survey: Survey): void;
  remove(): void;
}

export default function SurveyEditor({
  survey,
  errors,
  update,
  remove
}: Props) {
  function addQuestion() {
    update({
      ...survey,
      questions: [
        ...survey.questions,
        {
          type: "name",
          text: "What is your name?",
          required: true,
          variable: "name",
          options: []
        }
      ]
    });
  }

  const hasQuestions = survey.questions.length > 0;
  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center gap-2">
        <input
          type="text"
          className="input"
          required
          value={survey.name}
          onChange={(e) => update({ ...survey, name: e.currentTarget.value })}
        />
        <TeriaryBlueButton label="Delete survey" onClick={remove} />
      </div>

      {!hasQuestions && (
        <div className="pt-4 pb-4">This survey has no questions.</div>
      )}
      {hasQuestions && (
        <table className="border-separate border-spacing-6">
          <thead>
            <tr>
              <th className="text-left">Label</th>
              <th className="text-left">Type</th>
              <th className="text-left">Attribute</th>
              <th className="text-left">Required</th>
              <th className="text-left"></th>
            </tr>
          </thead>
          <tbody>
            {survey.questions.map((q, i) => (
              <QuestionEditor
                errors={errors[i] || {}}
                key={i}
                question={q}
                update={(value) => {
                  survey.questions[i] = value;
                  update({ ...survey });
                }}
                remove={() => {
                  survey.questions.splice(i, 1);
                  update({ ...survey });
                }}
              />
            ))}
          </tbody>
        </table>
      )}
      <div>
        <SecondaryBlueButton
          prefixIcon={<IconPlus />}
          label="Add Question"
          onClick={addQuestion}
        />
      </div>
    </div>
  );
}
