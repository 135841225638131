import * as React from "react";
import { FormGroup } from "../RightAsideHelpers";
import Select from "@/components/Select";
import { httpMethods, WorkflowNode } from "../../types";
import Input from "@/components/Input";
import { IconClose } from "@velaro/velaro-component-lib";
import { TeriaryGrayButton } from "@velaro/velaro-component-lib";
import { IconPlus } from "@velaro/velaro-component-lib";
import TextArea from "@/components/TextArea";
import { useMemo } from "react";
import { Toggle } from "@velaro/velaro-component-lib";
interface Props {
  node: WorkflowNode;
  onUpdate: (node: Partial<WorkflowNode>) => void;
}

export default function HttpEditor({ node, onUpdate }: Props) {
  const bodyIsValidJson = useMemo(() => {
    try {
      if (!node.body) {
        return true;
      }
      JSON.parse(node.body);
      return true;
    } catch (e) {
      return false;
    }
  }, [node.body]);

  return (
    <>
      <FormGroup label="Description">
        <Input
          value={node.description || ""}
          onChange={(description) => onUpdate({ description })}
        />
      </FormGroup>
      <FormGroup label="Method">
        <Select
          options={httpMethods}
          value={node.httpMethod}
          onChange={(httpMethod) => {
            onUpdate({ httpMethod });
          }}
        />
      </FormGroup>
      <FormGroup label="Url">
        <Input value={node.url || ""} onChange={(url) => onUpdate({ url })} />
      </FormGroup>
      <FormGroup label="Headers">
        <div className="border rounded-lg p-2 mb-4 bg-slate-gray-100 flex flex-col items-center">
          {node.headers &&
            node.headers.map((header, i) => (
              <React.Fragment key={i}>
                <div className="flex w-full items-center gap-1">
                  <Input
                    placeholder="Key"
                    className="my-2 flex grow"
                    value={header.key}
                    onChange={(key) => {
                      node.headers[i] = { ...header, key };
                      onUpdate({
                        headers: [...node.headers]
                      });
                    }}
                  />
                  <IconClose
                    onClick={() => {
                      onUpdate({
                        headers: node.headers.filter((_, index) => index !== i)
                      });
                    }}
                    className="cursor-pointer"
                    style={{ color: "#CBD5E1" }}
                  />
                </div>
                <Input
                  placeholder="Value"
                  className="my-2"
                  value={header.value}
                  onChange={(value) => {
                    node.headers[i] = { ...header, value };
                    onUpdate({
                      headers: [...node.headers]
                    });
                  }}
                />
                <div className="my-2 border border-slate-300 w-full"></div>
              </React.Fragment>
            ))}
          <TeriaryGrayButton
            prefixIcon={<IconPlus />}
            label="Add Header"
            onClick={() => {
              onUpdate({
                headers: [...(node.headers || []), { key: "", value: "" }]
              });
            }}
          />
        </div>
      </FormGroup>
      <FormGroup label="Body">
        <TextArea
          className={bodyIsValidJson ? "" : "border border-red-300"}
          value={node.body || ""}
          placeholder="JSON"
          onChange={(e) => onUpdate({ body: e.currentTarget.value })}
        />
      </FormGroup>
      <FormGroup label="Settings">
        <Toggle
          toggled={node.waitForResponse || false}
          changeToggle={() =>
            onUpdate({ waitForResponse: !node.waitForResponse })
          }
          label="Wait for response"
        />
      </FormGroup>
    </>
  );
}
