import React from "react";
import { useNavigate } from "react-router-dom";
import { UserModel } from "../types";
import { BackToUsersButton } from "../UserProfile";
import IconPersonAdd from "@/icons/IconPersonAdd";
import { PrimaryBlueButton } from "@velaro/velaro-component-lib";

interface Props {
  user: UserModel;
  setActiveStep(step: number): void;
  setShowSuccessModal(show: boolean): void;
}

export default function SuccessWizardStep(props: Props) {
  const navigate = useNavigate();
  return (
    <>
      <div className="flex justify-center">
        <div className="panel bg-white dark:bg-stone-300 rounded-md shadow-md p-8 mt-6 mx-8 w-1/2">
          <hr className="my-4 border-gray-100" />
          <div className="flex justify-center">
            <div className="text-lg font-semibold mb-4">All done!</div>
          </div>
          <div className="flex justify-center">
            <div className="">
              New user {props.user.displayName} has been successfully created.
            </div>
          </div>
          <div className="flex justify-center">
            <div>
              Verify in{" "}
              <span
                className="text-cornflower-blue-500 hover:underline cursor-pointer"
                onClick={() => navigate(`/users/${props.user.id}`)}
              >
                user profile
              </span>{" "}
              if the invitation was accepted.
            </div>
          </div>
          <div className="flex justify-between mt-16">
            <BackToUsersButton />
            <PrimaryBlueButton
              prefixIcon={<IconPersonAdd />}
              label="Add User"
              onClick={() => {
                props.setShowSuccessModal(false);
                props.setActiveStep(1);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
