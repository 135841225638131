import useAIConfigs from "@/hooks/useAIConfigs";
import IconArrowBack1 from "@/icons/IconArrowBack1";
import IconArrowBack2 from "@/icons/IconArrowBack2";
import * as React from "react";
import { Link, useParams } from "react-router-dom";
import { AIConfiguration } from "../Messaging/Bots/types";
import { LoadingSpinner } from "@velaro/velaro-component-lib";
import { set } from "lodash";

interface Props {
  title: string;
  subTitle?: string;
  headerActions?: React.ReactNode;
  children: React.ReactNode;
  backLink?: string;
}

export default function StickyRouteHeader(props: Props) {
  const { id } = useParams();
  const { aiConfigurations, loading } = useAIConfigs();
  const [config, setConfig] = React.useState<AIConfiguration>();

  React.useEffect(() => {
    if (aiConfigurations.length > 0 && id) {
      const config = aiConfigurations.find(
        (config) => config.id === parseInt(id)
      );
      if (config) {
        setConfig(config);
        return;
      }
    }
    setConfig(undefined);
  }, [aiConfigurations, id]);

  if (loading) {
    <LoadingSpinner />;
  }

  return (
    <>
      <div className="pb-4 mb-4 flex items-center justify-between -top-4 sticky z-50 bg-slate-gray-50">
        <div className="text-lg font-medium text-gray-800 flex flex-col">
          {props.backLink && (
            <span className="mr-2">
              <Link to={props.backLink}>
                <IconArrowBack2 />
              </Link>
            </span>
          )}
          <span>{config?.name || props.title}</span>
          <span className="text-sm font-normal text-gray-500">{props.subTitle}</span>
        </div>
        {props.headerActions && <span>{props.headerActions}</span>}
      </div>
      {props.children}
    </>
  );
}
